const wheelConfig = {
  background: '/images/wheels/fruit/bg.gif',
  blocks: [
    {
      padding: '22px',
      imgs: [{
        src: '/images/wheels/fruit/bg4@2x.png',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        rotate: true
      }]
    },
    {
      padding: '0px',
      imgs: [{
        src: '/images/wheels/fruit/bg1@2x.png',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        rotate: true
      }]
    },
    {
      padding: '0px',
      imgs: [{
        src: '/images/wheels/fruit/bg2@2x.png',
        top: '-20px',
        left: '0px',
        width: '70%',
        height: '70%',
        rotate: false
      }]
    },
  ],
  altPrizeColors: ['#ECEFE3', '#ECEFE3']
}

export default wheelConfig;
