import React, { useMemo, useRef, useEffect, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { template } from "lodash";
import {
  AccountCircle as AccountCircleIcon,
  Chair as ChairIcon,
} from '@mui/icons-material';
import { red, green, blue, yellow } from '@mui/material/colors';

// Utilize lodash template
const CURRENCY_TEMPLATE = {
  MYR: "RM<%= amount %>",
  THB: "฿<%= amount %>",
};

function PragmaticPlayDGA({ children, game }) {
  const containerRef = useRef(null);
  const [ lastResultSize, setLastResultSize ] = useState(24);
  const [ lastResultFontSize, setLastResultFontSize ] = useState(12);

  // Set lastResultSize based on containerRef width
  useEffect(() => {
    if (!containerRef?.current) return;
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const lastResultSize = Math.floor(containerWidth / 10) - 2;
    setLastResultSize(lastResultSize);
    setLastResultFontSize(lastResultSize / 2);
  }, [containerRef]);

  const currency = useMemo(() => {
    return game?.currency || '';
  }, [game]);

  const minBetLabel = useMemo(() => {
    const minBet = game?.tableLimits?.minBet || 0;
    const compiled = template(CURRENCY_TEMPLATE[currency] || "<%= amount %>");
    return compiled({ amount: minBet });
  }, [game, currency]);

  const maxBetLabel = useMemo(() => {
    const maxBet = game?.tableLimits?.maxBet || 0;
    const compiled = template(CURRENCY_TEMPLATE[currency] || "<%= amount %>");
    return compiled({ amount: maxBet });
  }, [game, currency]);

  const maxPlayers = useMemo(() => {
    return game?.tableLimits?.maxPlayers || 0;
  }, [game]);

  const totalSeatedPlayers = useMemo(() => {
    return game?.totalSeatedPlayers || 0;
  }, [game]);

  const dealerName = useMemo(() => {
    return game?.dealer?.name || '';
  }, [game]);

  const lastRouletteResult = useMemo(() => {
    const tableType = game?.tableType || '';

    if (tableType !== 'ROULETTE') return null;

    const last20Results = game?.last20Results || [];
    const last8Results = last20Results.slice(0, 8).map((result) => {
      const { color, result: resultNumber } = result;
      return {
        color,
        result: resultNumber,
      };
    });

    return last8Results;
  }, [game]);

  const lastSicBoResult = useMemo(() => {
    const tableType = game?.tableType || '';

    if (tableType !== 'SicBO') return null;

    const last20Results = game?.last20Results || [];
    const last8Results = last20Results.slice(0, 8).map((result) => {
      const { totalSum } = result;
      return {
        totalSum,
        bigOrSmall: totalSum >= 11 ? 'big' : 'small',
      };
    });

    return last8Results;
  }, [game]);

  const lastBaccaratResult = useMemo(() => {
    const tableType = game?.tableType || '';

    if (tableType !== 'BACCARAT') return null;

    const gameResult = game?.gameResult || [];
    const last8Results = gameResult.slice(0, 8).map((result) => {
      const { winner } = result;

      const winnerMap = {
        BANKER_WIN: 'B',
        PLAYER_WIN: 'P',
        TIE: 'T',
      };

      return {
        winner: winnerMap[winner],
      };
    });

    return last8Results;
  }, [game]);

  const lastDragonTigerResult = useMemo(() => {
    const tableType = game?.tableType || '';

    if (tableType !== 'DRAGONTIGER') return null;

    const gameResult = game?.gameResult || [];
    const last8Results = gameResult.slice(0, 8).map((result) => {
      const { winner } = result;

      const winnerMap = {
        DRAGON: '龙',
        TIGER: '虎',
        TIE: '和',
      };

      return {
        winner: winnerMap[winner],
      };
    });

    return last8Results;
  }, [game]);

  const lastAndarBaharResult = useMemo(() => {
    const tableType = game?.tableType || '';

    if (tableType !== 'ANDARBAHAR') return null;

    const gameResult = game?.gameResult || [];
    const last8Results = gameResult.slice(0, 8).map((result) => {
      const { winner } = result;

      const winnerMap = {
        ANDAR: 'A',
        BAHAR: 'B',
        TIE: 'T',
      };

      return {
        winner: winnerMap[winner],
      };
    });

    return last8Results;
  }, [game]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
         {
          lastRouletteResult && lastRouletteResult?.map((result, index) => {
            const { color, result: resultNumber } = result;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: color,
                  padding: 0.5,
                  width: lastResultSize,
                  height: lastResultSize,
                  margin: 0.1,
                  border: index === 0 ? "1px solid white" : "none",
                }}
              >
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: lastResultFontSize,
                    fontWeight: index === 0 ? "bold" : "normal",
                  }}
                  variant="subtitle2"
                >
                  {resultNumber}
                </Typography>
              </Box>
            );
          })
         }
         {
          lastSicBoResult && lastSicBoResult?.map((result, index) => {
            const { totalSum, bigOrSmall } = result;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: 'white',
                  padding: 0.5,
                  width: lastResultSize,
                  height: lastResultSize,
                  margin: 0.1,
                  border: index === 0 ? "1px solid white" : "none",
                }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    fontSize: bigOrSmall === 'big' ? lastResultFontSize * 1.25 : lastResultFontSize,
                    fontWeight: index === 0 ? "bold" : "normal",
                  }}
                  variant="subtitle2"
                >
                  {totalSum}
                </Typography>
              </Box>
            );
          })
         }
         {
          lastBaccaratResult && lastBaccaratResult?.map((result, index) => {
            const { winner } = result;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: winner === 'B' ? red[500] : winner === 'P' ? blue[500] : green[500],
                  padding: 0.5,
                  width: lastResultSize,
                  height: lastResultSize,
                  margin: 0.1,
                  border: index === 0 ? "1px solid white" : "none",
                }}
              >
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: lastResultFontSize,
                    fontWeight: index === 0 ? "bold" : "normal",
                  }}
                  variant="subtitle2"
                >
                  {winner}
                </Typography>
              </Box>
            );
          })
         }
         {
          lastDragonTigerResult && lastDragonTigerResult?.map((result, index) => {
            const { winner } = result;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: winner === '龙' ? red[500] : winner === '虎' ? yellow[500] : green[500],
                  padding: 0.5,
                  width: lastResultSize,
                  height: lastResultSize,
                  margin: 0.1,
                  border: index === 0 ? "1px solid white" : "none",
                }}
              >
                <Typography
                  sx={{
                    color: winner === '虎' ? 'black' : 'white',
                    fontSize: lastResultFontSize,
                    fontWeight: index === 0 ? "bold" : "normal",
                  }}
                  variant="subtitle2"
                >
                  {winner}
                </Typography>
              </Box>
            );
          })
         }
         {
          lastAndarBaharResult && lastAndarBaharResult?.map((result, index) => {
            const { winner } = result;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: winner === 'A' ? blue[500] : winner === 'B' ? red[500] : green[500],
                  padding: 0.5,
                  width: lastResultSize,
                  height: lastResultSize,
                  margin: 0.1,
                  border: index === 0 ? "1px solid white" : "none",
                }}
              >
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: lastResultFontSize,
                    fontWeight: index === 0 ? "bold" : "normal",
                  }}
                  variant="subtitle2"
                >
                  {winner}
                </Typography>
              </Box>
            );
          })
         }
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: 0.5,
            mt: 0.25,
          }}
        >
          <Typography variant="body1">
            {minBetLabel} - {maxBetLabel}
          </Typography>
        </Box>

      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          margin: 0.5,
          padding: 0.5,
          borderRadius: 2,
        }}
      >
        <AccountCircleIcon sx={{ marginRight: 0.5, fontSize: 16 }} />
        <Typography variant="body1">
          {dealerName}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          margin: 0.5,
          padding: 0.5,
          borderRadius: 2,
        }}
      >

        <Typography variant="body1">
          {
            `${totalSeatedPlayers} / ${maxPlayers}`
          }
        </Typography>
        <ChairIcon sx={{ marginLeft: 0.5, fontSize: 16 }} />
      </Box>
      {children}
    </Box>
  );
}

export default PragmaticPlayDGA;