import React from 'react';
import { useAuth } from 'hooks/useAuth';
import { useUserConfig } from 'hooks/useUserConfig';
import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth({ children }) {
  const auth = useAuth();
  const { companyId, lang, darkMode, themeName } = useUserConfig();
  const location = useLocation();

  if (auth.user) return children;

  const queryObj = {
    ...(!!companyId && {
      org: companyId
    }),
    lang,
    darkMode,
    themeName,
  };

  const params = new URLSearchParams(queryObj);

  return <Navigate to={`/login?${params.toString()}`} state={{ from: location }} replace />
}
