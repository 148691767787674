import Gen1Menu from './variant/Gen1Menu';
//import ApiMenu from './variant/ApiMenu';

// get url params from react-router-dom
import { useParams } from 'react-router-dom';
import {
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const GameMenu = () => {
  const { gameType } = useParams();
  const { t } = useTranslation();

  // if gameType ends with '-api', use ApiMenu
  // if (gameType.endsWith('-api')) {
  //   return (
  //     <ApiMenu gameType={gameType} />
  //   );
  // }

  switch (gameType) {
    case 'lpe88':
    case 'ntc33':
      return (
        <Gen1Menu gameType={gameType} />
      );

    default:
      // Return Alert with game menu not supported
      return (
        <Alert severity="error">
          {t('gameMenu.gameNotSupported')}
        </Alert>
      );
  }
}

export default GameMenu;
