import React, { useContext, useMemo, useState, useEffect, useRef, useCallback } from "react";
import CommonContext from 'features/context/commonContext';
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  SearchTwoTone as SearchIcon,
  ClearTwoTone as ClearIcon,
  ShuffleTwoTone as ShuffleIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import GameList from '../gameList/gen1.json';
import { orderBy, throttle, shuffle } from 'lodash';
import { useVirtualizer } from "@tanstack/react-virtual";
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import isIOS from 'utils/isIOS.js';
import { nanoid } from "nanoid";
import { useAuth } from 'hooks/useAuth';
import useGameLogo from 'hooks/useGameLogo';
import { get, find } from 'lodash';

function isPopGame(gameCode) {
  return gameCode.startsWith('pop_') || gameCode.endsWith('_pop');
}

function isGpasGame(gameCode) {
  return gameCode.startsWith('gpas_');
}

function SquareCardMedia({ image, alt }) {
  // const ref = useRef();

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (ref.current) {
  //       const containerWidth = ref.current.offsetWidth;
  //       ref.current.style.height = `${containerWidth}px`;
  //     }
  //   };

  //   handleResize(); // Initial resize

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <CardMedia
      component="img"
      //ref={ref}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
      image={image}
      alt={alt}
    />
  );
}

// function DialogTitleWithClose({ children, onClose, ...other }) {
//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       { onClose ? (
//         <IconButton
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//           onClick={onClose}
//           onMouseDown={(e) => e.preventDefault()}
//         >
//           <ClearIcon />
//         </IconButton>
//       ) : null }
//     </DialogTitle>
//   );
// }

const DEFAULT_SELECTED_GAME = {
  code: null,
  name: null,
  imgURL: null,
  color: null,
  type: null,
  jackpot: null,
};

const Gen1Menu = ({ gameType }) => {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { companySetting, games, gamesReady, gameIds, gameIdsReady } = useContext(CommonContext);
  const { t } = useTranslation();
  const [ selectedTab, setSelectedTab ] = useState('slot');
  const [ searchKeyword, setSearchKeyword ] = useState('');
  const [ searchKeywordThrottled, setSearchKeywordThrottled ] = useState('');
  const [ orderByField, ] = useState('name');
  const [ orderDirection, ] = useState('asc');
  const [ filteredGames, setFilteredGames ] = useState([]);
  const [ selectedGame, setSelectedGame ] = useState(DEFAULT_SELECTED_GAME);
  const parentRef = useRef(null);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ isIdle, setIsIdle ] = useState(true);
  const [ reqCorrelationId, setReqCorrelationId ] = useState(null);
  const [ shuffleState, setShuffleState ] = useState(false);
  const [ showSearch, setShowSearch ] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { getGameImagePath } = useGameLogo();

  const totalOffsetHeight = useMemo(
    () => {
      const announcement = document.getElementById('announcement');
      const appBar = document.getElementById('appBar');
      const bottomNav = document.getElementById('bottomNav');
      const announcementHeight = announcement?.offsetHeight || 0;
      const appBarHeight = appBar?.offsetHeight || 0;
      const bottomNavHeight = bottomNav?.offsetHeight || 0;
      const gameTypeTabHeight = 48;
      const ret = announcementHeight + appBarHeight + bottomNavHeight + gameTypeTabHeight + 40;
      return ret;
    }, []
  );

  const virtualizerConfig = useMemo(
    () => {
      const lanes = isMobile ? 2 : 4;
      const width = 100 / lanes;
      const left = lanes === 1 ? 0 : width;

      return {
        lanes,
        width,
        left,
      }
    }, [isMobile]
  );


  const game = useMemo(
    () => {
      if (!gamesReady) return null;

      return find(games, { type: gameType, isEnabled: true });
    }, [games, gamesReady, gameType]
  );

  const supportedGameList = useMemo(
    () => {
      if (!companySetting) return [];
      const { country } = companySetting;

      if (country === 'sg') {
        return GameList.filter((game) => isPopGame(game.code));
      } else if (country === 'au') {
        return GameList.filter((game) => isGpasGame(game.code));
      }

      return GameList;
    }, [companySetting]
  );

  const supportedLiveGame = useMemo(
    () => {
      if (!companySetting) return false;
      const { country } = companySetting;

      return ['my'].includes(country);
    }, [companySetting]
  );

  useEffect(
    () => {
       /*
        Game item sample:
        {
          "code": "aogs",
          "name": "Age of Gods",
          "imgURL": "https://s3.eu-west-1.amazonaws.com/assets.marketplace.playtech.com.eu-west-1/marketplace/marketplace/PTC_AgeOfTheGodsicon_256x256_1527073917978.jpg",
          "color": "#214bd4",
          "type": "slot",
          "jackpot": true
        }
      */

      let ret = [];

      if (selectedTab === 'jackpot') {
        ret = supportedGameList.filter((game) => game.jackpot === true);
      } else {
        ret = supportedGameList.filter((game) => game.type === selectedTab);
      }

      if (searchKeywordThrottled) {
        ret = ret.filter((game) => {
          const { name = '', description = '' } = game;
          const nameMatch = name.toLowerCase().includes(searchKeywordThrottled.toLowerCase());
          const descriptionMatch = description.toLowerCase().includes(searchKeywordThrottled.toLowerCase());
          return nameMatch || descriptionMatch;
        });
      }

      if (shuffleState) {
        const shuffledGames = shuffle(ret);
        setFilteredGames(shuffledGames);
      } else {
        const filteredGames = orderBy(ret, [orderByField], [orderDirection]);
        setFilteredGames(filteredGames);
      }
    }, [selectedTab, orderByField, orderDirection, supportedGameList, shuffleState, searchKeywordThrottled]
  );

  useEffect(
    () => {
      const throttleSearchKeyword = throttle(
        () => {
          setSearchKeywordThrottled(searchKeyword);
        }, 1000
      );
      throttleSearchKeyword();
    }, [searchKeyword, selectedTab, supportedGameList]
  );

  useEffect(
    () => {
      if (selectedTab !== 'search') return;
      if (!searchKeyword) {
        setFilteredGames([]);
        return;
      }

      const throttledsetFilteredGames = throttle(
        () => {
          const filteredGames = supportedGameList.filter((game) => game.name.toLowerCase().includes(searchKeyword.toLowerCase()));
          setFilteredGames(filteredGames);
        }, 1000
      );
      throttledsetFilteredGames();
    }, [searchKeyword, selectedTab, supportedGameList]
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredGames.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 180,
    overscan: 3,
    lanes: virtualizerConfig.lanes,
    horizontal: false,
  });

  const gameId = useMemo(
    () => {
      if (!gameIdsReady) return null;
      return gameIds.find((gameId) => gameId.gameType === gameType);
    }, [gameIds, gameIdsReady, gameType]
  );

  const gameUsername = useMemo(
    () => {
      if (!gameId) return '';
      return gameId?.gameId;
    }, [gameId]
  );

  const gamePassword = useMemo(
    () => {
      if (!gameId) return '';
      return gameId?.gamePassword;
    }, [gameId]
  );

  const handleGameClick = useCallback(
    (game) => (event) => {
      event.preventDefault();
      setSelectedGame(game);
    }, []
  );

  const handleGameReset = useCallback(
    (event) => {
      event.preventDefault();
      setSelectedGame(DEFAULT_SELECTED_GAME);
    }, []
  );

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('game-ids');

    const onTaskProcessed = (data) => {
      const { action } = data;
      if (action !== 'startGame') return;
      onStartGame(data);
    };

    const onStartGame = (data) => {
      const { correlationId, gameUrl, success } = data;

      // Ignore if correlationId (current request) is not the same as the one returned from server (possibly timeout)
      if (correlationId !== reqCorrelationId) return;

      setIsIdle(true);
      setReqCorrelationId(null);

      if (!success) {
        setGlobalErrorMessage({ err: new Error('Failed to start game') });
        return;
      }

      if (gameUrl && isMounted) {
        const target = isIOS() ? '_self' : '_blank';
        window.open(gameUrl, target);
      }
    };

    service.on('processed', onTaskProcessed);

    return () => {
      isMounted = false;
      service.removeListener('processed', onTaskProcessed);
    };
  }, [setGlobalErrorMessage, t, reqCorrelationId]);

  /*
    Implement timeout when correlationId is changed
  */

  useEffect(() => {
    let timeout = null;

    if (reqCorrelationId) {
      timeout = setTimeout(() => {
        setIsIdle(true);
        setGlobalErrorMessage({ err: new Error('Failed to start game') });
      }, 15000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    }
  }, [reqCorrelationId, t, setGlobalErrorMessage]);

  const handleStartGame = useCallback(
    (gameCode, gameCategory, realMode = true) => async (event) => {
      try {
        const corrId = nanoid();
        setIsIdle(false);
        setReqCorrelationId(corrId);

        await feathers.service('game-ids').find({
          query: {
            correlationId: corrId,
            __action__: 'startGame',
            gameType,
            gameCode,
            gameCategory,
            realMode,
          }
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setIsIdle(true);
      }
    }, [gameType, setGlobalErrorMessage]
  );

  const handleTabChange = (event, newValue) => {
    if (newValue === null) return;
    setSelectedTab(newValue);
  };

  const handleShuffleClick = useCallback(
    (event) => {
      event.preventDefault();
      setShuffleState((prevShuffleState) => !prevShuffleState);
    }, []
  );

  const handleSearchClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowSearch((prevShowSearch) => !prevShowSearch);
    }, []
  );

  if (!gameUsername || !gamePassword) {
    return (
      <Alert severity="error">
        {t('gameMenu.gameIdNotFound')}
      </Alert>
    );
  }

  return (
    <>
      <Dialog
        open={selectedGame?.code !== null}
        onClose={handleGameReset}
      >
        <DialogTitle onClose={handleGameReset}>
          {selectedGame.name}
        </DialogTitle>
        <DialogContent>
          <Box
            component='img'
            src={selectedGame.imgURL}
            alt={selectedGame.name}
            sx={{
              width: '100%',
              objectFit: 'cover',
              mb: 1,
            }}
          />
          <ButtonGroup
            size="large"
            fullWidth
          >
            <Button
              disabled={!isIdle}
              variant="contained"
              color="success"
              onClick={handleStartGame(selectedGame?.code, selectedGame?.type)}
            >
              {t('gameMenu.play')}
            </Button>
            {
              selectedGame.type === 'slot' &&
              <Button
                disabled={!isIdle}
                variant='outlined'
                color="success"
                onClick={handleStartGame(selectedGame?.code, selectedGame?.type, false)}
              >
                {t('gameMenu.try')}
              </Button>
            }
          </ButtonGroup>
          {
            !isIdle &&
            <Box sx={{ mt: 1 }}>
              <LinearProgress />
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGameReset}>
            {t('gameMenu.close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
        <Card>
          <CardHeader
            action={
              <Stack spacing={1} direction='row'>
              <IconButton onClick={handleShuffleClick}>
                <ShuffleIcon
                  sx={{
                    color: shuffleState ? 'success.main' : 'text.primary',
                  }}
                />
              </IconButton>
              <IconButton onClick={handleSearchClick}>
                <SearchIcon
                  sx={{
                    color: showSearch ? 'success.main' : 'text.primary',
                  }}
                />
              </IconButton>
              </Stack>
            }
            title={(
              <>
                <Typography variant='h6'>
                  {game?.name[lang]}
                </Typography>
              </>
            )}
            avatar={
              <Avatar
                src={getGameImagePath(gameType)}
                variant="rounded"
              />
            }
          />
          <Divider />
          <CardContent>
            <Box>
              <ToggleButtonGroup
                value={selectedTab}
                exclusive
                onChange={handleTabChange}
                aria-label="game type"
                size="small"
                fullWidth
              >
                {
                  supportedLiveGame &&
                  <ToggleButton value="live" aria-label="live">
                    {t('gameMenu.live')}
                  </ToggleButton>
                }
                <ToggleButton value="slot" aria-label="slot">
                  {t('gameMenu.slot')}
                </ToggleButton>
                <ToggleButton value="jackpot" aria-label="jackpot">
                  {t('gameMenu.jackpot')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </CardContent>
          {
            showSearch &&
            <CardActions>
              <FormControl fullWidth>
                <TextField
                  autoFocus={true}
                  fullWidth
                  variant="outlined"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  placeholder={t('gameMenu.searchPlaceholder')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchKeyword('')}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </CardActions>
          }
        </Card>
      </Box>
      <Box
        ref={parentRef}
        sx={{
          mt: 1,
          height: `calc(100vh - ${totalOffsetHeight}px)`,
          maxWidth: {
            sm: 'sm',
            md: 'xl',
          },
          mx: 'auto',
          overflow: 'auto',
        }}
      >
        <Box
          component="div"
          sx={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {
            rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const game = filteredGames[virtualRow.index];
              const key = `${game.code}-${virtualRow.index}`;
              return (
                <Box
                  component="div"
                  key={key}
                  data-index={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: `${virtualRow.lane * virtualizerConfig.left}%`,
                    width: `${virtualizerConfig.width}%`,
                    //height: `${virtualRow.size}px`,
                    //height: `${rows[virtualRow.index]}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Card sx={{ m: .5 }} elevation={4}>
                    <CardActionArea onClick={handleGameClick(game)}>
                      <SquareCardMedia
                        image={game.imgURL}
                        alt={game.name}
                      />
                    </CardActionArea>
                  </Card>
                </Box>
              )
            }
          )}
        </Box>
      </Box>
    </>
  );
}

export default Gen1Menu;
