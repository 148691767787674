import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LineIcon } from 'features/customIcons/CustomIcons';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(32),
  },
}));

export default function SupportDialog(props) {
  const { open, onClose, title, description, tgLink = null, waLink = null, lineLink = null, defaultLocale } = props;
  const { t } = useTranslation();

  const handleLinkClicked = (url) => (event) => {
    event.preventDefault();
    window.open(url, '_blank');
  };

  const generateButtons = useMemo(() => {
    const lineButton = (
      <StyledButton
        key='support-line'
        fullWidth
        disabled={!lineLink}
        onClick={handleLinkClicked(lineLink)}
        variant="contained"
        startIcon={<LineIcon />}
        color='secondary'
        sx={{ borderLeft: '5px solid #00B900' }}
        size='large'
      >
        <Box component='span' sx={{ marginLeft: 'auto' }}>
          {t('Via Line')}
        </Box>
      </StyledButton>
    );

    const waButton = (
      <StyledButton
        key='support-wa'
        fullWidth
        disabled={!waLink}
        onClick={handleLinkClicked(waLink)}
        variant="contained"
        startIcon={<WhatsAppIcon />}
        color='secondary'
        sx={{ borderLeft: '5px solid #25D366' }}
        size='large'
      >
        <Box component='span' sx={{ marginLeft: 'auto' }}>
          {t('Via WhatsApp')}
        </Box>
      </StyledButton>
    );

    const tgButton = (
      <StyledButton
        key='support-tg'
        fullWidth
        disabled={!tgLink}
        onClick={handleLinkClicked(tgLink)}
        variant="contained"
        startIcon={<TelegramIcon />}
        color='secondary'
        sx={{ borderLeft: '5px solid #0088CC' }}
        size='large'
      >
        <Box component='span' sx={{ marginLeft: 'auto' }}>
          {t('Via Telegram')}
        </Box>
      </StyledButton>
    );

    if (defaultLocale === 'th') {
      return [lineButton, waButton, tgButton];
    }

    return [tgButton, waButton, lineButton];
  }, [lineLink, tgLink, waLink, t, defaultLocale]);

  return (
   <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
        {description}
        </DialogContentText>
        <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
          {generateButtons}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

SupportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
