import { createTheme } from '@mui/material/styles';
import bgPattern from './background/moroccan-flower.webp';

export default createTheme({
    /*shape: {
      borderRadius: 20
    },*/
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundImage: `url(${bgPattern})`,
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
   /*       outlinedPrimary: {
            color: '#616161',
            borderColor: 'rgba(97, 97, 97, 0.5)',
            '&:hover': {
              borderColor: 'rgba(97, 97, 97, 0.4)',
              backgroundColor:  'rgba(97, 97, 97, 0.08)',
            }
          }*/
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 4
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 4
          }
        }
      }
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#052B72',
      },
      secondary: {
        main: '#616161',
      },
      text: {
        icon: '#FFFFFF',
      },
      error: {
        main: '#f50057',
      },
      warning: {
        main: '#ff9100',
      },
      info: {
        main: '#0091ea',
      },
      success: {
        main: '#00e676',
      },
/*      background: {
        default: '#303030',
        paper: '#212121'
      }*/
    },
    typography: {
      fontFamily: [
        '"Source Sans Pro"',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
    },
    custom: {
      name: 'blue',
      wheelConfig: {
        blocks: [
          {
            padding: '6px',
            imgs: [{
              src: '/images/wheels/blue/bg1.png',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              rotate: true
            }]
          },
          {
            padding: '0px',
            imgs: [{
              src: '/images/wheels/blue/bg2.png',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              rotate: false
            }]
          },
        ],
        altPrizeColors: ['#FFF', '#FFF', '#234262', '#192F46']
      }
    }
  });
