import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Decimal from 'decimal.js';
import { get, includes, set, sortedUniq, find } from 'lodash';
import CommonContext from 'features/context/commonContext';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ImageAvatar from 'features/avatar/ImageAvatar';
import { useAuth } from 'hooks/useAuth';
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useUserConfig } from 'hooks/useUserConfig';
import CountUp from 'react-countup';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/ms';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export default function AmountSelector(props) {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { userData, userDataReady, tiers, tiersReady, pointBoosters, pointBoostersReady, companySetting, companySettingReady } = useContext(CommonContext);
  const [ rewardDialogOpen, setRewardDialogOpen ] = useState(false);
  const [ clickedReward, setClickedReward ] = useState(null);
  const [ cuReward, setCuReward ] = useState({ amount: { start: 0, end: 0 }, percentage: { start: 0, end: 0 } });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { skipDepositReward, syncSkipDepositReward } = useUserConfig();

  const companySkipDepositReward = useMemo(
    () => {
      return get(companySetting, 'walletConfig.skipDepositReward', false);
    }, [companySetting]
  );

  const savedSkipDepositReward = useMemo(
    () => {
      if (skipDepositReward === null) return companySkipDepositReward;
      return skipDepositReward;
    }, [companySkipDepositReward, skipDepositReward]
  );

  const firstTimeDeposit = useMemo(
    () => {
      const recentCashIn = get(userData, 'recentCashIn.minute', []);
      return !recentCashIn.length;
    }, [userData]
  );

  const minDepositLimit = useMemo(
    () => {
      const { minDeposit: { '$numberDecimal': minDeposit = 10 } = {} } = companySetting?.transaction || {};
      return Number(minDeposit);
    }, [companySetting]
  );

  const maxDepositLimit = useMemo(
    () => {
      const { maxDeposit: { '$numberDecimal': maxDeposit = 30000 } = {} } = companySetting?.transaction || {};
      return Number(maxDeposit);
    }, [companySetting]
  );

  const depositDenominations = useMemo(
    () => {
      const depositDenominations = get(companySetting, 'transaction.depositDenominations', []);
      const firstDepositDenominations = get(companySetting, 'transaction.firstDepositDenominations', []);
      const rawDenominations = firstTimeDeposit ? firstDepositDenominations : depositDenominations;

      const denominations = rawDenominations.map(d => {
        const dec = new Decimal(d?.$numberDecimal || 0);
        return dec.toNumber();
      });
      return denominations;
    }, [companySetting, firstTimeDeposit]
  );

  const defaultDepositAmount = useMemo(
    () => {
      const defaultAmount = depositDenominations[0] || 0;
      return defaultAmount.toFixed(2);
    }, [depositDenominations]
  );

  const amountSchema = Yup.object().shape({
    rewardFlag: Yup.bool().required(t("Required")),
    amount: Yup.number().required(t("Required"))
      .min(minDepositLimit, t('Min amount', { amount: minDepositLimit }))
      .max(maxDepositLimit, t('Max amount', { amount: maxDepositLimit }))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: defaultDepositAmount,
      rewardFlag: !savedSkipDepositReward
    },
    validationSchema: amountSchema,
    onSubmit: async values => {
      const { amount = 0, rewardFlag } = values;

      try {
        await syncSkipDepositReward(!rewardFlag);
      } catch (err) {
        console.error(err);
      } finally {
        navigate(`${amount}/${rewardFlag}`);
      }
    },
  });

  const isReady = useMemo(
    () => {
      return (userDataReady && tiersReady && pointBoostersReady && companySettingReady);
    }, [userDataReady, tiersReady, pointBoostersReady, companySettingReady]
  );

  const decMinAmount = useMemo(
    () => {
      const min = new Decimal(minDepositLimit);
      return min;
    }, [minDepositLimit]
  );

  const decMaxAmount = useMemo(
    () => {
      const max = new Decimal(maxDepositLimit);
      return max;
    }, [maxDepositLimit]
  );

  const decAmount = useMemo(
    () => {
      const ret = new Decimal(get(formik, 'values.amount', '0'));
      return ret;
    }, [formik]
  );

  const handleDialogClose = () => {
    setRewardDialogOpen(false);
  };

  const allBoosters = useMemo(() => {
    const valid = [], invalid = [];
    if (!pointBoostersReady) return { valid, invalid };

    for (const booster of pointBoosters) {
      const isEnabled = get(booster, 'isEnabled', false);

      if (!isEnabled) continue;

      const startsAt = get(booster, 'startsAt');
      const endsAt = get(booster, 'endsAt');
      if (!startsAt || !endsAt) continue;

      const isActivePeriod = dayjs().isBetween(dayjs(startsAt), dayjs(endsAt), 'day', '[)');
      if (!isActivePeriod) {
        const dateNow = dayjs();
        const diffStart = dayjs(startsAt).diff(dateNow, 'day', true);
        const diffEnd = dayjs(endsAt).diff(dateNow, 'day', true);

        if (diffStart > 0) {
          invalid.push({
            ...booster,
            when: dayjs(startsAt).locale(lang).fromNow()
          });
          continue;
        } else if (diffEnd < 0) {
          invalid.push({
            ...booster,
            when: dayjs(endsAt).locale(lang).fromNow()
          });
          continue;
        }
        continue;
      }

      const daysAllowed = sortedUniq(get(booster, 'daysAllowed', []));
      if (!daysAllowed.length) continue;

      const todayIndex = dayjs().isoWeekday();
      const isAllowed = includes(daysAllowed, todayIndex);

      if (!isAllowed) {
        let nextStartIn = -1;

        for (let i = 1; i <= 7; i++) {
          const futureDay = (todayIndex + i) % 7;
          if (includes(daysAllowed, futureDay)) {
            nextStartIn = i;
            break;
          }
        }
        if (nextStartIn === -1) continue;

        invalid.push({
          ...booster,
          when: dayjs().add(nextStartIn, 'd').startOf('d').locale(lang).fromNow()
        });
        continue;
      }

      valid.push({
        ...booster,
        endsIn: dayjs(endsAt).locale(lang).fromNow()
      });
    }

    return {
      valid,
      invalid
    };
  }, [pointBoosters, pointBoostersReady, lang]);

  const currentTier = useMemo(
    () => {
      if (!isReady) return null;

      const tierId = get(userData, 'tierId');
      const tier = find(tiers, { _id: tierId });

      return tier;
    }, [isReady, userData, tiers]
  );

  const tierReward = useMemo(
    () => {
      if (!currentTier) {
        return {
          percentage: 0,
          amount: 0
        };
      }

      const id = get(currentTier, '_id');
      const name = get(currentTier, `name[${lang}]`, '');
      const imageId = get(currentTier, `photo[${lang}]`);
      const decTierPointMultiplier = new Decimal(get(currentTier, 'pointMultiplier.$numberDecimal', '0'));
      const decTierReward = decTierPointMultiplier.times(decAmount);
      return {
        id,
        name,
        imageId,
        percentage: decTierPointMultiplier.times(100).toNumber(0),
        amount: decTierReward.toNumber()
      };
    }, [decAmount, currentTier, lang]
  );

  const boosterRewards = useMemo(
    () => {
      let ret = [];

      for (const booster of allBoosters?.valid) {

        const id = get(booster, '_id');
        const name = get(booster, `name[${lang}]`, '');
        const imageId = get(booster, `photo[${lang}]`);
        const boosterMultiplier = new Decimal(get(booster, 'pointMultiplier.$numberDecimal', '0'));
        const boosterReward = boosterMultiplier.times(decAmount);
        const endsIn = get(booster, 'endsIn', 0);
        ret.push({
          id,
          name,
          imageId,
          percentage: boosterMultiplier.times(100).toNumber(0),
          amount: boosterReward.toNumber(),
          endsIn,
        })
      }

      return ret;
    }, [decAmount, allBoosters, lang]
  );

  const upcomingBoosters = useMemo(
    () => {
      let ret = [];

      for (const booster of allBoosters?.invalid) {

        const id = get(booster, '_id');
        const name = get(booster, `name[${lang}]`, '');
        const imageId = get(booster, `photo[${lang}]`);
        const boosterMultiplier = new Decimal(get(booster, 'pointMultiplier.$numberDecimal', '0'));
        const boosterReward = boosterMultiplier.times(decAmount);
        const when = get(booster, 'when', '');
        ret.push({
          id,
          name,
          imageId,
          percentage: boosterMultiplier.times(100).toNumber(0),
          amount: boosterReward.toNumber(),
          when
        })
      }

      return ret;
    }, [decAmount, allBoosters, lang]
  );

  const totalReward = useMemo(
    () => {
      let decTotalPercentage = new Decimal(0);
      let decTotalAmount = new Decimal(0);

      decTotalPercentage = decTotalPercentage.add(tierReward.percentage);
      decTotalAmount = decTotalAmount.add(tierReward.amount);

      for (const booster of boosterRewards) {
        decTotalPercentage = decTotalPercentage.add(booster.percentage);
        decTotalAmount = decTotalAmount.add(booster.amount);
      }

      return {
        percentage: decTotalPercentage.toNumber(),
        amount: decTotalAmount.toNumber()
      }
    }, [tierReward, boosterRewards]
  );

  useEffect(() => {
    setCuReward(prev => {

      if (prev?.amount?.end === totalReward.amount) {
        return prev;
      }

      return {
        amount: {
          start: prev?.amount?.end,
          end: totalReward.amount
        },
        percentage: {
          start: prev?.percentage.end,
          end: totalReward?.percentage
        }
      }
    });
  }, [totalReward]);

  const handleOnAmountChange = useCallback(
    (event) => {
      event.preventDefault();
      const value = get(event, 'target.value', '');
      const sanitizedValue = value.replace(/\D/g, '');
      const amountRaw = new Decimal(sanitizedValue);
      const amount = amountRaw.dividedBy(100);
      const amountStr = amount.gt(decMaxAmount) ? decMaxAmount.toFixed(2) : amount.toFixed(2);
      formik.setFieldValue('amount', amountStr);
    }, [formik, decMaxAmount]
  );

  const handleAutoAmount = useCallback(
    (value) => (event) => {
      event?.preventDefault();
      const decValue = new Decimal(value);
      const checkedValue = decValue.lt(decMinAmount) ? decMinAmount.toFixed(2) : decValue.toFixed(2);
      set(event, 'target.value', checkedValue);
      handleOnAmountChange(event);
    }, [handleOnAmountChange, decMinAmount]
  );

  const rewardFlag = useMemo(
    () => {
      return get(formik, 'values.rewardFlag', false);
    }, [formik]
  );

  const handleRewardClicked = (reward) => (event) => {
    event.preventDefault();
    setClickedReward(reward);
    setRewardDialogOpen(true);
  }

  const handleRewardChanged = (event) => {
    const val = get(event, 'target.checked', false);
    formik.setFieldValue('rewardFlag', val);
  };

  if (!isReady) {
    return (
      <Paper sx={{ p: 3, my: 1 }} elevation={2}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card elevation={4} component="form" onSubmit={formik.handleSubmit}>
            <CardHeader title={t('Deposit')} />
            <Divider />
            <CardContent>
              <Box>
                <Grid spacing={1} container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        inputProps={{ inputMode: 'numeric' }}
                        inputMode='numeric'
                        id="amount"
                        name="amount"
                        label={t('Amount')}
                        value={formik.values.amount}
                        onBlur={formik.handleBlur}
                        onChange={handleOnAmountChange}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                            {_CASH_SYMBOL}
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  </Grid>
                {
                  depositDenominations.map(label => {
                    return (
                      <Grid item xs={3} key={`amt-${label}`}>
                        <Button fullWidth onClick={handleAutoAmount(label)} color='info' variant='contained' size='small'>{label}</Button>
                      </Grid>
                    )
                  })
                }
                </Grid>
              </Box>
              <Box sx={{ pt: 2, pl: 2 }}>
                <FormGroup>
                  {
                    companySkipDepositReward ? (
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '0.9rem',
                          }
                        }}
                        control={<Checkbox onChange={handleRewardChanged} checked={get(formik, 'values.rewardFlag', true)} />}
                        label={t('Deposit Reward Strict Agreement')}
                      />
                    ) : (
                      <FormControlLabel
                        control={<Checkbox onChange={handleRewardChanged} checked={get(formik, 'values.rewardFlag', true)} />}
                        label={t('Deposit Reward Agreement')}
                      />
                    )

                  }
                </FormGroup>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button type="submit" variant='contained'>{t('Next')}</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={_POINT_SYMBOL}
              title={
                <CountUp
                  start={cuReward?.percentage.start}
                  end={cuReward?.percentage.end}
                  duration={0.88}
                  separator=" "
                  decimals={2}
                  decimal="."
                  prefix={`${t('Reward')} `}
                  suffix='%'
                >
                  {({ countUpRef, start }) => (
                    <Box
                      sx={{
                        textDecoration: rewardFlag ? 'none' : 'line-through',
                      }}
                      component='span' ref={countUpRef}
                    />
                  )}
                </CountUp>
              }
              subheader={
                <CountUp
                  start={cuReward?.amount.start}
                  end={cuReward?.amount.end}
                  duration={0.88}
                  separator=" "
                  decimals={2}
                  decimal="."
                  prefix=''
                  suffix=''
                >
                  {({ countUpRef, start }) => (
                    <Box
                      sx={{
                        textDecoration: rewardFlag ? 'none' : 'line-through',
                      }}
                      component='span' ref={countUpRef}
                    />
                  )}
                </CountUp>
              }
            />
            <Divider />
            <CardContent>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ImageAvatar onClick={handleRewardClicked(tierReward)} sx={{ cursor: 'pointer', width: 40, height: 40 }} variant='rounded' alt={tierReward.name} imageId={tierReward.imageId} />
                  </Grid>
                  {
                    boosterRewards.map(b => {
                      return (
                        <Grid key={b.id} item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <ImageAvatar onClick={handleRewardClicked(b)} sx={{ cursor: 'pointer', width: 40, height: 40 }} variant='rounded' alt={b.name} imageId={b.imageId} />
                        </Grid>
                      );
                    })
                  }
                  {
                    upcomingBoosters.map(b => {
                      return (
                        <Grid key={b.id} item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <ImageAvatar onClick={handleRewardClicked(b)}sx={{ cursor: 'pointer', width: 40, height: 40, filter: 'grayscale(100%)' }} variant='rounded' alt={b.name} imageId={b.imageId} />
                        </Grid>
                      );
                    })
                  }
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog onClose={handleDialogClose} open={rewardDialogOpen}>
      {
        !!clickedReward &&
        (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 1 }}>
                <ImageAvatar sx={{ width: 80, height: 80 }} variant='rounded' alt={clickedReward.name} imageId={clickedReward.imageId} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant='h6' gutterBottom>
                {clickedReward.name}
              </Typography>
              <Typography variant='subtitle2' gutterBottom>{t('Reward percentage')}: {`${clickedReward.percentage?.toFixed(2)}%`}</Typography>
              <Typography variant='subtitle2' gutterBottom>{t('Reward amount')}: {`${_POINT_SYMBOL} ${clickedReward.amount?.toFixed(2)}`}</Typography>
              {
                !!clickedReward.endsIn &&
                <Alert severity='info' sx={{ my: 1 }}>
                  {
                    t('Ends in', { when: clickedReward.endsIn })
                }
                </Alert>
              }
              {
                !!clickedReward.when &&
                <Alert severity='warning' sx={{ my: 1 }}>
                  {
                    t('Availability', { when: clickedReward.when })
                  }
                </Alert>
              }
            </DialogContent>
          </>
        )
      }
      </Dialog>
    </Box>
  );
}