import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import { LuckyWheel } from '@lucky-canvas/react';
import Box from '@mui/material/Box';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import { get, shuffle, sortBy, map, filter, maxBy } from 'lodash';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Decimal from 'decimal.js';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import soundUrl from 'assets/sounds/wheel_sprite.mp3';
import useLongPress from 'hooks/useLongPress';
import NeonPanel from './NeonPanel';
import { useUserConfig } from 'hooks/useUserConfig';
import Confetti from 'react-confetti';
import useWindowSize from 'hooks/useWindowSize';
import Chance from 'chance';
import Color from 'color';

import CountUp from 'react-countup';
import IconButton from '@mui/material/IconButton';
import VolumeOffIcon from '@mui/icons-material/VolumeOffTwoTone';
import VolumeOnIcon from '@mui/icons-material/VolumeUpTwoTone';
import Alert from '@mui/material/Alert';
import GameButton from './GameButton';
import { useTheme } from '@mui/material/styles';

export default function Redeem(props) {
  const theme = useTheme();
  const myLucky = useRef();
  const { t } = useTranslation();
  const { companySetting, wallet, walletReady, redemptionDeals, redemptionDealsReady } = useContext(CommonContext);
  const [ dealIndex, setDealIndex ] = useState(-1);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { noNeonLights } = useUserConfig();
  const windowSize = useWindowSize();
  const [ playConfetti, setPlayConfetti ] = useState(false);
  const [ confettiCount, setConfettiCount ] = useState(100);
  const altPrizeColors = get(theme, 'custom.wheelConfig.altPrizeColors', []);
  const wheelBlocks = get(theme, 'custom.wheelConfig.blocks', []);
  const wheelBackground = get(theme, 'custom.wheelConfig.background');

  const [ redemption, setRedemption ] = useState(null);
  const [ isAutoSpin, setIsAutoSpin] = useState(false);
  const [ isMuted, setIsMuted ] = useState(true);
  const [ cuWin, setCuWin ] = useState({ start: 0, end: 0 });
  const [ cuPoint, setCuPoint ] = useState({ start: 0, end: 0 });
  const pointRef = useRef(null);
  const paletteMode = get(theme, 'palette.mode');
  const darkMode = paletteMode === 'dark' ? true : false;

  const [ playState, setPlayState ] = useState('idle');

  const isSpinning = useMemo(
    () => {
      return playState !== 'idle';
    }, [playState]
  );

  const redemptionId = useMemo(() => {
    return get(redemption, '_id');
  }, [redemption]);

  const redemptionCashReward = useMemo(() => {
    return new Decimal(get(redemption, 'cashAmount.$numberDecimal', '0')).toNumber();
  }, [redemption]);

  const [ play, { stop, sound } ] = useSound(soundUrl, {
    sprite: {
      point: [0, 530],
      spinStart: [1000, 4800],
      spinEnd: [6000, 400],
      cash: [7000, 580],
    }
  });

  useEffect(
    () => {
      return () => {
        stop();
      }
    }, [stop]
  );

  useEffect(
    () => {
      if (!sound) return;
      if (!isMuted) sound?.fade(0, 1, 500);
      else sound?.fade(1, 0, 500);
    }, [isMuted, sound]
  );

  const wheelBgColor = useMemo(
    () => {
      const themeName = get(theme, 'custom.name');
      const mainColor = themeName === 'blue' ? '#FFFFFF' : theme.palette.primary.main;
      const mainGradient = Color(mainColor).fade(0.8);

      const ret = `linear-gradient(160deg, ${mainGradient.darken(0.1)} 0%, ${mainGradient} 35%, ${mainGradient.lighten(0.3)} 100%)`;
      return ret;
    }, [theme]
  );

  const wheelConfig = useMemo(
    () => {
      return {
        speed: isAutoSpin ? 30 : 10,
        accelerationTime: isAutoSpin ? 50 : 500,
        decelerationTime: isAutoSpin ? 50 : 1500,
        spinDuration: isAutoSpin ? 100 : 4000
      }
    }, [isAutoSpin]
  );

  const spinDurationMemo = useMemo(
    () => {
      return wheelConfig?.spinDuration;
    }, [wheelConfig]
  );

/*  const spinDecelerationTimeMemo = useMemo(
    () => {
      return wheelConfig?.decelerationTime;
    }, [wheelConfig]
  );*/

  const pointBalance = useMemo(
    () => {
      if (!walletReady) return 0;
      const decPointBalance = new Decimal(get(wallet, 'pointBalance.$numberDecimal', '0'));
      return decPointBalance.toNumber();
    }, [wallet, walletReady]
  );

  const config = useMemo(
    () => {
      return {
        blocks: wheelBlocks,
        defaultStyle: {
          /*fontSize: '20px'*/
        },
        activeStyle: {
        },
        defaultConfig: {
          speed: wheelConfig.speed,
          accelerationTime: wheelConfig.accelerationTime,
          decelerationTime: wheelConfig.decelerationTime,
          offsetDegree: 22.5,
          stopRange: 0.8
        }
      };
    }, [wheelConfig, wheelBlocks]
  );

  const deals = useMemo(
    () => {
      if (!redemptionDealsReady) return null;
      const filtered = filter(redemptionDeals, { isEnabled: true });
      const sorted = sortBy(filtered, [
        function(d) {
          return new Decimal(get(d, 'point.$numberDecimal', '0')).toNumber();
        }
      ]);

      return sorted;
    }, [redemptionDealsReady, redemptionDeals]
  );

  const deal = useMemo(
    () => {
      return get(deals, `[${dealIndex}]`, null);
    }, [deals, dealIndex]
  );

  const decDealPoint = useMemo(
    () => {
      return new Decimal(get(deal, 'point.$numberDecimal', '0'))
    }, [deal]
  );

  const dealId = useMemo(
    () => {
      return get(deal, '_id');
    }, [deal]
  );

  useEffect(() => {
    if (!deals || !deals?.length) return;
    setDealIndex(0);
  }, [deals]);

  const isLowBalance = useMemo(
    () => {
      if (!deal) return true;
      const decPointBalance = new Decimal(pointBalance);
      const decPoint = new Decimal(get(deal, 'point.$numberDecimal', '999999999'));
      return decPointBalance.lt(decPoint) ? true : false;
    }, [deal, pointBalance]
  );

  const cashRewards = useMemo(() => {
    if (!deal) return [];
    const rewards = get(deal, 'cashRewards', []);
    const shuffledRewards = shuffle(rewards);
    const mappedRewards = map(shuffledRewards, function(r) {
      return get(r, '$numberDecimal', '0');
    });
    return mappedRewards;
  }, [deal]);

  const themeFont = useMemo(
    () => {
      const fontFamily = get(theme, 'typography.fontFamily', 'Ubuntu, sans-serif');
      return fontFamily;
    }, [theme]
  );

  const currencySymbol = useMemo(
    () => {
      return companySetting?.currencySymbol || '';
    }, [companySetting]
  );

  const prizes = useMemo(() => {
    if (!cashRewards.length) return [];

    const mappedPrizes = map(cashRewards, function(cashReward, index) {
      const fonts = [{
        text: `${currencySymbol}${cashReward}`,
        top: '30px',
        fontSize: '1rem',
        fontColor: altPrizeColors[index % altPrizeColors.length],
        fontWeight: 700,
        fontStyle: themeFont
      }];

      return {
        top: '0px',
        //background: color,
        fonts
      };
    });

    return mappedPrizes;
  }, [cashRewards, themeFont, altPrizeColors, currencySymbol]);

  useEffect(() => {
    const service = feathers.service('point-redemptions');

    const onPatched = (data) => {
      setRedemption(data);
    };

    service.on('patched', onPatched);

    return () => {
      service.removeListener('patched', onPatched);
    };
  }, []);

  useEffect(() => {
    if (playState !== 'redeem') return;

    async function redeem() {
      try {
        const redemption = await feathers.service('point-redemptions').create({
          dealId
        });

        setRedemption(redemption);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    if (isLowBalance) setPlayState('lowBalance');
    else redeem();
  }, [dealId, playState, isLowBalance, setGlobalErrorMessage]);

  useEffect(() => {
    if (playState !== 'lowBalance') return;

    const animateLowBalanceTimeout = setTimeout(() => {
      pointRef?.current?.animate({
        transform: ['rotate(15deg)', 'rotate(30deg)', 'rotate(-30deg)']
      }, 100);
      setIsAutoSpin(false);
      setPlayState('idle');
    }, 100);

    return () => {
      clearTimeout(animateLowBalanceTimeout);
    }
  }, [playState]);

  const redemptionState = useMemo(
    () => {
      return get(redemption, 'state', '');
    }, [redemption]
  );

  useEffect(
    () => {
      if (redemptionState !== 'deducted') return;

      const decPointAmount = new Decimal(get(redemption, 'pointAmount.$numberDecimal', '0'));

      setCuPoint(prev => {
        const { end: endAmount } = prev;
        const newAmount = decPointAmount.add(endAmount).toNumber();

        return {
          start: prev?.end, end: newAmount
        }
      });

      setPlayState('animateStart');
    }, [redemption, redemptionState]
  );

  useEffect(
    () => {
      if (redemptionState !== 'deducted') return;
      let isMounted = true;

      if (isMounted) play({ id: 'point' });

      return () => {
        isMounted = false;
      }
    }, [redemptionState, play]
  );

  const rewardIndex = useMemo(
    () => {
      if (!redemptionCashReward) return -1;

      const matchedIndex = cashRewards.reduce(function (all, current, index) {
        const decCurrent = new Decimal(current);
        if (!decCurrent.eq(redemptionCashReward)) return all;
        return [...all, index];
      }, []);

      if (!matchedIndex.length) return -1;

      const chance = new Chance();
      const ret = chance.pickone(matchedIndex);
      return ret;
    }, [cashRewards, redemptionCashReward]
  );

  useEffect(
    () => {
      if (playState !== 'animateStart') return;

      myLucky.current && myLucky.current.play();

      const animateStopTimeout = setTimeout(() => {
        setPlayState('animateStop');
      }, spinDurationMemo);

      return () => {
        clearTimeout(animateStopTimeout);
      };
    }, [playState, spinDurationMemo]
  );

  useEffect(
    () => {
      if (playState !== 'animateStop' || rewardIndex < 0) return;

      stop();

      myLucky.current && myLucky.current.stop(rewardIndex);
      setPlayConfetti(true);
    }, [playState, rewardIndex, stop]
  );

  useEffect(
    () => {
      if (playState !== 'animateStart') return;
      let isMounted = true;

      if (isMounted && !isAutoSpin) play({ id: 'spinStart' });

      return () => {
        isMounted = false;
      }
    }, [playState, play, isAutoSpin]
  );

  useEffect(() => {
    if (playState !== 'claim' || !redemptionId) return;

    async function claim() {
      try {
        await feathers.service('point-redemptions').patch(redemptionId, {
          state: 'initial'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    claim();
  }, [playState, redemptionId, setGlobalErrorMessage]);

  useEffect(
    () => {
      if (playState !== 'claim') return;
      let isMounted = true;

      if (isMounted) play({ id: 'spinEnd' });

      return () => {
        isMounted = false;
      }
    }, [playState, play, stop]
  );

  useEffect(
    () => {
      if (redemptionState !== 'initial') return;

      const decCashReward = new Decimal(redemptionCashReward);

      setCuWin(prev => {
        const decBeforeCash = new Decimal(get(prev, 'end', '0'));
        const decAfterCash = decBeforeCash.plus(decCashReward);
        return {
          start: decBeforeCash.toNumber(),
          end: decAfterCash.toNumber()
        }
      });

      setPlayState('idle');
      setRedemption(null);
    }, [redemptionState, redemptionCashReward]
  );

  useEffect(
    () => {
      if (redemptionState !== 'initial') return;
      let isMounted = true;

      if (isMounted) play({ id: 'cash' });

      return () => {
        isMounted = false;
      }
    }, [redemptionState, play]
  );

  useEffect(() => {
    if (!isAutoSpin) return;
    if (playState === 'idle') setPlayState('redeem');
  }, [isAutoSpin, playState]);

  useEffect(() => {
    if (isAutoSpin || playState === 'redeem') setPlayConfetti(false);
  }, [playState, isAutoSpin]);

  const maxCashReward = useMemo(
    () => {
      return maxBy(cashRewards, r => (new Decimal(r).toNumber()));
    }, [cashRewards]
  );

  useEffect(
    () => {
      if (!redemptionCashReward) return;
      const decMaxCashReward = new Decimal(maxCashReward);
      setConfettiCount(decMaxCashReward.eq(redemptionCashReward) ? 120 : 30);
    }, [maxCashReward, redemptionCashReward]
  );

  const onSpinEnded = useCallback(
    () => {
      setPlayState('claim');
    }, []
  );

  const handleChipChanged = (type) => (event) => {
    event.preventDefault();

    if (type === 'plus') {
       setDealIndex(prev => {
        const newIndex = prev + 1;
        if (newIndex < deals.length) return newIndex;
        return deals.length - 1;
      });
    } else {
      setDealIndex(prev => {
        const newIndex = prev - 1;
        if (newIndex > 0) return newIndex;
        return 0;
      });
    }
  };

  const handleSpinClicked = useCallback(
    (event) => {
      if (isAutoSpin) {
        setIsAutoSpin(false);
        return;
      }

      setPlayState(prev => {
        return prev === 'idle' ? 'redeem' :
          prev === 'animateStart' ? 'animateStop' :
            prev;
      });
    }, [isAutoSpin]
  );

  const handleSpinLongPressed = useCallback(
    (event) => {
      event.preventDefault();
      const newIsAutoSpin = !isAutoSpin;
      setIsAutoSpin(newIsAutoSpin);

      if (newIsAutoSpin) {
        setPlayState(prev => {
          return prev === 'idle' ? 'redeem' : prev;
        });
      }
    }, [isAutoSpin]
  );

  function handleVolumeClicked(event) {
    event.preventDefault();
    setIsMuted(prevState => !prevState);
  }

  const longPressEvent = useLongPress(handleSpinLongPressed, handleSpinClicked, { shouldPreventDefault: false, delay: 500 });

  if (!redemptionDealsReady || !walletReady) {
    return (
      <Loader open={true} />
    );
  }

  if (deals && !deals.length) {
    return (<Alert severity="info">{t('No record')}</Alert>);
  }

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Box sx={{ display: playConfetti ? 'block' : 'none' }}>
      {
        playConfetti &&
        <Confetti
          run={true}
          gravity={0.1}
          numberOfPieces={confettiCount}
          recycle={false}
          wind={0}
          width={windowSize?.width}
          height={windowSize?.height}
          colors={[theme.palette.primary.main, theme.palette.primary.light, theme.palette.primary.dark]}
        />
      }
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <NeonPanel noNeonLights={noNeonLights} stopAnimation={isSpinning} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            width: '100%',
            py: 2,
            background: wheelBgColor,
            ...(
              !!wheelBackground && {
                backgroundImage: `url("${wheelBackground}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }
            )
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '300px',
              height: '300px',
              mx: 'auto',
            }}>
              <Box sx={{ textAlign: 'center', mb: 1, position: 'absolute', right: 0, top: 0, zIndex: 20 }}>
                <IconButton
                  size="small" onClick={handleVolumeClicked}
                  sx={{
                    color: 'white',
                  }}
                >
                  {
                    isMuted ? <VolumeOffIcon /> : <VolumeOnIcon />
                  }
                </IconButton>
              </Box>
              <LuckyWheel
                ref={myLucky}
                width="300px"
                height="300px"
                blocks={config.blocks}
                prizes={prizes}
                //buttons={config.buttons}
                defaultConfig={config.defaultConfig}
                defaultStyle={config.defaultStyle}
                activeStyle={config.activeStyle}
                /*onStart={handleSpinClicked}*/
                onEnd={onSpinEnded}
              >

              </LuckyWheel>
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                visibility: 'none',
                width: '2rem',
                height: '2rem'
              }} onClick={handleSpinClicked}>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <Paper variant='outlined' sx={{ bgcolor: darkMode ? '#6c6c6c' : '#f0f0f0' }}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', width: '100%', p: 1.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body1' ref={pointRef}>{_POINT_SYMBOL}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexGrow: 1, flexSize: 2, justifyContent: 'right' }}>
                    <CountUp
                      start={cuPoint.start}
                      end={cuPoint.end}
                      duration={0.88}
                      separator=" "
                      decimals={2}
                      decimal="."
                      prefix=""
                      suffix=""
                    >
                      {({ countUpRef, start }) => (
                        <Typography variant='body1' ref={countUpRef} />
                      )}
                    </CountUp>
                  </Box>
                </Box>
                <Divider orientation='vertical' flexItem={true} />
                <Box sx={{ display: 'flex', width: '100%', p: 1.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body1'>{_CASH_SYMBOL}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexGrow: 1, flexSize: 2, justifyContent: 'right' }}>
                    <CountUp
                      start={cuWin.start}
                      end={cuWin.end}
                      duration={0.88}
                      separator=" "
                      decimals={2}
                      decimal="."
                      prefix=""
                      suffix=""
                    >
                      {({ countUpRef, start }) => (
                        <Typography variant='body1' ref={countUpRef} />
                      )}
                    </CountUp>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ py: 2 }}>
                <Stack direction='row' spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <GameButton size='xsmall' disabled={!!isSpinning} onClick={handleChipChanged('minus')}>{`-`}</GameButton>
                  <GameButton size='large' { ...longPressEvent }>
                    {
                      (!!isAutoSpin || !!isSpinning) ? t('Stop') :
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='h5'>
                          {`${_POINT_SYMBOL}`}
                        </Typography>
                        <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
                          {`${decDealPoint.toString()}`}
                        </Typography>
                      </Box>
                    }
                  </GameButton>
                  <GameButton size='xsmall' disabled={!!isSpinning} onClick={handleChipChanged('plus')}>{`+`}</GameButton>
                </Stack>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='overline' sx={{ color: 'text.secondary' }}>
                    {t('Long press for auto')}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
