import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import LanguageCardHeader from './LanguageCardHeader.js';

export default function AvatarLogo() {
  const { t } = useTranslation();

  return (
    <LanguageCardHeader
      title={<Typography variant='h5'>{t("branding:name")}</Typography>}
      subheader={<Typography variant='subtitle2'>{t("branding:tagline")}</Typography>}
      avatar={<Avatar variant='rounded' alt={t("branding:name")} src='/logo512.png'/>}
    />
  );
}
