import NewsContext from './newsContext';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import useFeathers from 'hooks/useFeathersV2';
import { useAuth } from 'hooks/useAuth';

function NewsProvider({ children }) {
  const { user } = useAuth();
  const { lang } = user;
  const [ page, setPage ] = useState(0);
  const [ total, setTotal ] = useState(0);
  const [ limit ] = useState(10);
  const [ totalPage, setTotalPage ] = useState(0);
  const [ filter, setFilter ] = useState(null);
  const [ sort, setSort ] = useState({
    pinnedAt: -1,
    publishedAt: -1
  });

  useEffect(
    () => {
      setPage(0);
    }, [filter, sort]
  );

  const query = useMemo(
    () => {
      return {
        query: {
          $limit: limit,
          $skip: page * limit,
          ...(sort && {
            $sort: sort
          }),
          ...(filter && {
            ...filter
          }),
          forceUpdate: lang // force update when lang changes
        }
      }
    }, [page, limit, sort, filter, lang]
  );

  const { result, isFetching, error } = useFeathers('news', query);

  const gotoPage = useCallback(
    (pageToGo) => {
      if (pageToGo < 0 || pageToGo >= totalPage) return;
      setPage(pageToGo);
    }, [totalPage]
  );

  useEffect(
    () => {
      const { total, limit } = result;
      setTotal(total);

      // To prevent divide by zero
      if (total === 0) {
        setTotalPage(0);
        return;
      }

      setTotalPage(Math.ceil(total / limit));
    }, [result]
  );

  const allTags = useMemo(
    () => {
      const { tags = [] } = result;
      return tags;
    }, [result]
  );

  const data = useMemo(
    () => {
      const { data = [] } = result;
      return data;
    }, [result]
  );

  const errorMessage = useMemo(
    () => {
      return error?.message || '';
    }, [error]
  );

  return (
    <NewsContext.Provider value={{
      data,
      allTags,
      total,
      totalPage,
      page,
      isFetching,
      errorMessage,
      gotoPage,
      // gotoNextPage,
      // gotoPrevPage,
      setFilter,
      setSort,
    }}>
      {children}
    </NewsContext.Provider>
  );
}

export default NewsProvider;
