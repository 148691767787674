import React from 'react';
import { useTranslation } from 'react-i18next';
import CardMedia from '@mui/material/CardMedia';
import LanguageCardHeader from './LanguageCardHeader.js';

export default function AvatarLogo() {
  const { t } = useTranslation();

  return (
    <>
      <LanguageCardHeader />
      <CardMedia
        height="200"
        component="img"
        image="/images/login/big-logo.png"
        alt={t("branding:name")}
        sx={{ p: "1em", objectFit: "contain" }}
      />
    </>
  );
}
