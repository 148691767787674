import { createContext } from 'react';

const GuestContext = createContext({
  companyId: '',
  supportedLocales: [],
  defaultLocale: '',
  supportedCountryCodes: [],
  ready: false,
});

export default GuestContext;
