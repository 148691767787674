import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { _CASH_SYMBOL, _POINT_SYMBOL } from 'utils/symbol';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { get, unset, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import RemarkIcon from '@mui/icons-material/MailTwoTone';
import PotIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';
import AutoSizer from 'react-virtualized-auto-sizer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProofOfTransfer from 'features/img/ProofOfTransfer';
import FilterDialog from './FilterDialog';
import FilterIcon from '@mui/icons-material/FilterAltTwoTone';
import CalendarIcon from '@mui/icons-material/CalendarMonthTwoTone';
import DateDialog from './DateDialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import useGameLogo from 'hooks/useGameLogo';

const FILTER_TYPES = {
  'deposit': 'deposit',
  'withdrawal': 'withdrawal',
  'cashrebate': 'cashrebate',
  'tier': 'tier',
  'booster': 'booster',
  'referral': 'referral',
  'luckydraw': 'luckydraw',
  'vip': 'vip',
  'redemption': 'redemption',
  'adjustment': 'adjustment',
  'balancetransfer': 'balancetransfer',
}

const LOADING = 1;
const LOADED = 2;

export default function History() {
  const { t } = useTranslation();
  const [ items, setItems ] = useState({});
  const [ itemStatusMap, setItemStatusMap ] = useState({});
  const [ itemCount, setItemCount ] = useState(0);

  const [ lastFetched, setLastFetched ] = useState(new Date());
  const [ status, setStatus ] = useState('idle');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const dpService = feathers.service(`/deposits`);
  const wdService = feathers.service(`/withdrawals`);
  const [ openDialog, setOpenDialog ] = useState({
    open: false,
    remark: '',
    potRefId: null
  });
  const [ openFilterDialog, setOpenFilterDialog ] = useState(false);
  const [ openDateDialog, setOpenDateDialog ] = useState(false);
  const [ date, setDate ] = useState('today');
  const [ filters, setFilters ] = useState(Object.values(FILTER_TYPES));
  const { getGameImagePath } = useGameLogo();

  const query = useMemo(
    () => {
      if (!date || !filters.length) return null;

      return {
        $aggregate: 'history',
        dateRange: date,
        filters
      };
    }, [date, filters]
  );

  const rowSize = useMemo(
    () => {
      return {
        base: 144,
        /*extra: 32*/
      }
    }, []
  );

  const onUpdated = useCallback(
    () => {
      setLastFetched(new Date());
    },
    []
  );

  useEffect(() => {
    dpService.on('updated', onUpdated);
    dpService.on('patched', onUpdated);

    return () => {
      dpService.removeListener('updated', onUpdated);
      dpService.removeListener('patched', onUpdated);
    };
  }, [dpService, onUpdated]);

  useEffect(() => {
    wdService.on('updated', onUpdated);
    wdService.on('patched', onUpdated);

    return () => {
      wdService.removeListener('updated', onUpdated);
      wdService.removeListener('patched', onUpdated);
    };
  }, [wdService, onUpdated]);

  useEffect(() => {
    setItems({});
    setItemStatusMap({});
    setItemCount(0);
    // this will trigger the initial fetch
    //setLastFetched(new Date());
  }, [query]);

  useEffect(() => {
    if (!query) return;
    let isMounted = true;

    async function fetch() {
      try {
        const parsedQuery = dateRangeParser(query);
        const q = {
          ...parsedQuery,
          $skip: 0,
          $limit: 10
        };

        setStatus('loading');
        const result = await feathers.service('wallets').find({ query: q });

        if (!isMounted) return;

        const data = get(result, 'data', []);
        const total = get(result, 'total', 0);

        let loadedItemStatusMap = {};

        for (let index = 0; index < data.length; index++) {
          loadedItemStatusMap[index] = LOADED;
        }

        setItemStatusMap(prevState => {
          return { ...prevState, ...loadedItemStatusMap }
        });

        let itemsMap = {};

        data.forEach(function(d, index) {
          itemsMap[index] = d;
        });

        setItems(prevState => {
          return  {
            ...prevState,
            ...itemsMap
          }
        });
        setItemCount(total);

      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setStatus('idle');
      }
    }

    fetch();

    return () => {
      isMounted = false;
    };
  }, [lastFetched, query, setGlobalErrorMessage]);

  const isItemLoaded = useCallback(
    (index) => {
      return !!itemStatusMap[index];
    }, [itemStatusMap]
  );

  const loadMoreItems = useCallback(
    (startIndex, stopIndex) => {
      let loadingItemStatusMap = {};

      for (let index = startIndex; index <= stopIndex; index++) {
        loadingItemStatusMap[index] = LOADING;
      }

      setItemStatusMap(prevState => {
        return { ...prevState, ...loadingItemStatusMap }
      });

      return new Promise(async (resolve, reject) => {
          try {
            const parsedQuery = dateRangeParser(query);

            const q = {
              ...parsedQuery,
              $skip: startIndex,
              $limit: stopIndex - startIndex + 1
            }

            const result = await feathers.service('wallets').find({ query: q });
            const data = get(result, 'data', []);
            const total = get(result, 'total', 0);

            let loadedItemStatusMap = {};

            for (let index = startIndex; index <= stopIndex; index++) {
              loadedItemStatusMap[index] = LOADED;
            }

            setItemStatusMap(prevState => {
              return { ...prevState, ...loadedItemStatusMap }
            });

            let itemsMap = {};

            data.forEach(function(d, index) {
              itemsMap[index + startIndex] = d;
            });

            setItems(prevState => {
              return  {
                ...prevState,
                ...itemsMap
              }
            });

            if (startIndex === 0) {
              setItemCount(total);
            }
            resolve();
          } catch (err) {
            setGlobalErrorMessage({ err });
            reject(err);
          }
        }
      );
    }, [query, setGlobalErrorMessage]
  );

  function dateRangeParser(query) {
    const dateRange = get(query, 'dateRange');
    if (!dateRange) return query;
    // (h)our, (d)day, (M)onth, (y)ear
    const regexp = /(last|today|yesterday)(\d*)([hdMy]*)/gm;
    const match = regexp.exec(dateRange);

    let dateTo = dayjs().endOf('day');
    let dateFrom = dayjs();

    let g1 = get(match, '[1]', '');
    const g2 = get(match, '[2]', '');
    const g3 = get(match, '[3]', '');

    if (g1 === '') g1 = 'today';

    if (g1 === 'today') {
      dateFrom = dateFrom.startOf('day');
    } else if (g1 === 'yesterday') {
      const previousDay = dateFrom.subtract(1, 'day');
      dateFrom = previousDay.startOf('day');
      dateTo = previousDay.endOf('day');
    } else {
      dateFrom = dateFrom.subtract(g2, g3).startOf('day');
    }
    const newQuery = { ...query };

    unset(newQuery, 'dateRange');
    set(newQuery, 'dateFrom', dateFrom.format());
    set(newQuery, 'dateTo', dateTo.format());

    return newQuery;
  }

  const handleRemarkClicked = useCallback(
    (remark) => (event) => {
      event.preventDefault();
      setOpenDialog({
        open: true,
        remark,
        potRefId: null
      });
    }, []
  );

  const handlePotClicked = useCallback(
    (potRefId) => (event) => {
      setOpenDialog({
        open: true,
        remark: '',
        potRefId
      });
    }, []
  );

  const Row = useCallback(
    (props) => {
      const { index, style } = props;

      const loadingStatus = get(itemStatusMap, `${index}`);

      if (loadingStatus === LOADING) {
        return (
          <Skeleton variant="text" animation="wave" width="100%" height={40} />
        );
      } else if (loadingStatus === LOADED) {
        const item = get(items, `${index}`);
        if (!item) return null;
        let type = get(item, `__type`, '');
        let state = get(item, 'state', 'pending');
        if (!['done', 'canceled'].includes(state)) state = 'pending';

        const [deposit, withdrawal] = [FILTER_TYPES.deposit, FILTER_TYPES.withdrawal];
        const isLiveHistory = [deposit, withdrawal].includes(type);
        const isBt = type === FILTER_TYPES.balancetransfer;
        const remark = get(item, 'remark');
        const potRefId = get(item, 'potRefId');
        const gameType = get(item, 'gameType');
        const showState = isLiveHistory || isBt;
        const isAuto = get(item, 'isAuto', false);

        let decCashAmount = null;
        let decPointAmount = null;
        switch (type) {
          case FILTER_TYPES.redemption:
            decCashAmount = new Decimal(get(item, 'cashAmount.$numberDecimal', '0'));
            decPointAmount = new Decimal(get(item, 'pointAmount.$numberDecimal', '0')).negated();
            break;
          case FILTER_TYPES.deposit:
          case FILTER_TYPES.cashrebate:
            decCashAmount = new Decimal(get(item, 'amount.$numberDecimal', '0'));
            break;
          case FILTER_TYPES.withdrawal:
            decCashAmount = new Decimal(get(item, 'amount.$numberDecimal', '0')).negated();
            break;
          case FILTER_TYPES.adjustment:
            const balanceType = get(item, 'balanceType');
            const adjustmentType = get(item, 'type');
            const negated = adjustmentType === 'plus' ? 1 : -1;
            if (balanceType === 'cash') {
              decCashAmount = new Decimal(get(item, 'amount.$numberDecimal', '0')).times(negated);
            } else {
              decPointAmount = new Decimal(get(item, 'amount.$numberDecimal', '0')).times(negated);
            }
            break;
          case FILTER_TYPES.balancetransfer:
            const btType = get(item, 'type');
            if (btType === 'deposit') {
              type = 'POINT_IN';
              decCashAmount = new Decimal(get(item, 'amount.$numberDecimal', '0')).negated();
            } else {
              type = 'POINT_OUT';
              decCashAmount = new Decimal(get(item, 'amount.$numberDecimal', '0'));
            }
            break;
          case FILTER_TYPES.referral:
            decCashAmount = new Decimal(get(item, 'cashAmount.$numberDecimal', '0'));
            decPointAmount = new Decimal(get(item, 'pointAmount.$numberDecimal', '0'));
            if (decPointAmount.eq(0)) decPointAmount = null;
            if (decCashAmount.eq(0)) decCashAmount = null;
            break;
          default:
            decPointAmount = new Decimal(get(item, 'amount.$numberDecimal', '0'));
        }

        const createdAt = dayjs(get(item, 'createdAt'));

        return (
          <Paper variant='outlined' square sx={{ px: 1.5, py: 1, maxWidth: 'xs' }} style={style}>
            <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <Box sx={{ minHeight: 48, alignItems: 'center', display: 'flex' }}>
                  <Stack direction='row' spacing={1}>
                    <Chip size='small' label={createdAt.format('MMM DD HH:mm:ss')} />
                    {
                      (showState && state === 'pending') && <Chip color='info' size='small' label={t(state)} />
                    }
                    {
                      (showState && state === 'canceled') && <Chip color='error' size='small' label={t(state)} />
                    }
                    {
                      (!!isAuto) && <Chip color='warning' size='small' label={t('Auto')} />
                    }
                  </Stack>
                  <Stack sx={{ flexGrow: 1 }} direction='row-reverse' spacing={0}>
                    {
                      (remark && !isBt) &&
                      <IconButton onClick={handleRemarkClicked(remark)}>
                        <RemarkIcon />
                      </IconButton>
                    }
                    {
                      potRefId &&
                      <IconButton onClick={handlePotClicked(potRefId)}>
                        <PotIcon />
                      </IconButton>
                    }
                </Stack>

                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='h6' sx={{ color: 'text.secondary', fontStyle: 'oblique' }}>
                  { t(type) }
                  </Typography>
                  {
                    gameType && (
                      <Box sx={{ ml: 1, width: '2.2rem', height: '2.2rem' }} component='img' src={getGameImagePath(gameType)} alt={`${gameType} logo`} />
                    )
                  }
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Stack direction='row-reverse' spacing={1}>
                  {
                    decCashAmount &&
                    <Typography variant='subtitle2'>
                      { `${_CASH_SYMBOL} ` }
                      <Typography variant='subtitle2' component='span' >
                        {`${decCashAmount.gt(0) ? '+' : ''}${decCashAmount.toFixed(2)}`}
                      </Typography>
                    </Typography>
                  }
                  {
                    decPointAmount &&
                    <Typography variant='subtitle2'>
                      { `${_POINT_SYMBOL} ` }
                      <Typography variant='subtitle2' component='span'>
                        {`${decPointAmount.gt(0) ? '+' : ''}${decPointAmount.toFixed(2)}`}
                      </Typography>
                    </Typography>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        );
      } else {
        return null;
      }
    }, [items, itemStatusMap, t, handleRemarkClicked, handlePotClicked, getGameImagePath]
  );

  const calcItemSize = useCallback(
    (index) => {
      const { base } = rowSize;
      return base;
    }, [rowSize]
  );

  const handleDialogClose = () => {
    setOpenDialog(prevState => ({ ...prevState, open: false }));
  };

  const handleFilterDialogOpen =() => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = useCallback(
    (filters) => {
      setFilters(filters);
      setOpenFilterDialog(false);
    }, []
  );

  const handleDateDialogOpen =() => {
    setOpenDateDialog(true);
  };

  const handleDateDialogClose = useCallback(
    (date) => {
      setDate(date);
      setOpenDateDialog(false);
    }, []
  );

  const totalOffsetHeight = useMemo(
    () => {
      const announcement = document.getElementById('announcement');
      const appBar = document.getElementById('appBar');
      const bottomNav = document.getElementById('bottomNav');
      const announcementHeight = announcement?.offsetHeight || 0;
      const appBarHeight = appBar?.offsetHeight || 0;
      const bottomNavHeight = bottomNav?.offsetHeight || 0;
      const filtersHeight = 136;
      const ret = announcementHeight + appBarHeight + bottomNavHeight + filtersHeight + 40;
      return ret;
    }, []
  );

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <DateDialog open={openDateDialog} onClose={handleDateDialogClose} />
      <FilterDialog  open={openFilterDialog} onClose={handleFilterDialogClose} />
      <Dialog fullWidth open={openDialog.open} onClose={handleDialogClose}>
        <DialogTitle>
          { openDialog.remark ? t('Remark') : t('POT') }
        </DialogTitle>
        <DialogContent dividers>
          {
            openDialog.remark && (
              <DialogContentText>
                {openDialog.remark}
              </DialogContentText>
            )
          }
          {
            openDialog.potRefId &&
            <Paper elevation={8} sx={{ p: 1 }}>
              <ProofOfTransfer sx={{ width: '100%' }} potId={openDialog.potRefId} />
            </Paper>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleDialogClose} autoFocus>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('Filter')} />
            <Divider />
            <CardContent>
              <Stack direction='row' spacing={1}>
                <Button color='info' size='small' onClick={handleDateDialogOpen} variant='contained' startIcon={<CalendarIcon />}>
                  { t(date) }
                </Button>
                <Button color='info' size='small' onClick={handleFilterDialogOpen} variant='contained' startIcon={<FilterIcon />}>
                  {`${filters?.length || 0}`}
                </Button>
              </Stack>
              {
                status !== 'idle' && <Fade in={status !== 'idle'}>
                  <Box sx={{ mt: 2 }}>
                    <LinearProgress />
                  </Box>
                </Fade>
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
        {
          itemCount > 0 ?
          <Paper
            elevation={4}
            sx={{
              height: `calc(100vh - ${totalOffsetHeight}px)`,
              maxWidth: 'sm',
              borderRadius: 0
            }}
          >
            <AutoSizer>
              {
                ({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}
                  >
                    {({ onItemsRendered, ref }) => (


                        <VariableSizeList
                          height={height}
                          itemCount={itemCount}
                          estimatedItemSize={rowSize.base}
                          itemSize={calcItemSize}
                          onItemsRendered={onItemsRendered}
                          ref={ref}
                          width={width}
                          minimumBatchSize={5}
                        >
                          {
                            Row
                          }
                        </VariableSizeList>

                    )}
                  </InfiniteLoader>
                )
              }
            </AutoSizer>
          </Paper> :
          <Alert severity="info">{t('No record')}</Alert>
        }
        </Grid>
      </Grid>
    </Box>
  );
}
