import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { hex2rgba } from 'utils/color';
import { get } from 'lodash';

const SpanAnimation = styled(Box, {
  shouldForwardProp: (prop) => (
    prop !== 'stopAnimation' &&
    prop !== 'noNeonLights' &&
    prop !== 'direction'
  )
})(({ theme, stopAnimation = false, noNeonLights = false, direction }) => {

  const rgbPrimaryFade = hex2rgba(get(theme, 'palette.primary.main', '#FFFFFF'), 0.5);

  const hexPrimary = theme.palette.primary.main;

  const animation = useMemo(
    () => {
      let anim;

      switch (direction) {
        case 'top':
          anim = keyframes`
            25% {
              width: 100%;
              opacity: 1;
            }
            30%,
            100% {
              opacity: 0;
            }
          `;
          break;

        case 'right':
          anim = keyframes`
            0%,
            25% {
              opacity: 0;
              height: 0;
            }

            50% {
              opacity: 1;
              height: 100%;
            }

            55%,
            100% {
              height: 100%;
              opacity: 0;
            }
          `;
          break;

        case 'bottom':
          anim = keyframes`
            0%,
            50% {
              opacity: 0;
              width: 0;
            }

            75% {
              opacity: 1;
              width: 100%;
            }

            76%,
            100% {
              opacity: 0;
            }
          `;
          break;

        case 'left':
          anim = keyframes`
            0%,
            75% {
              opacity: 0;
              bottom: 0;
              height: 0;
            }
            100% {
              opacity: 1;
              height: 100%;
            }
          `;
          break;

        default:
      }

      if (direction === 'right') return `${anim} 3s linear infinite`;
      return `${anim} 3s ease-in-out infinite`;
    }, [direction]
  );

  const styles = useMemo(
    () => {
      switch (direction) {
        case 'top':
          return {
            top: 0,
            left: 0,
            width: 0,
            height: '5px',
            background: `linear-gradient(90deg, transparent 50%, ${rgbPrimaryFade}, ${hexPrimary})`
          }

        case 'right':
          return {
            top: 0,
            right: 0,
            width: '5px',
            height: 0,
            background: `linear-gradient(180deg, transparent 30%, ${rgbPrimaryFade}, ${hexPrimary})`
          }

        case 'bottom':
          return {
            right: 0,
            bottom: 0,
            height: '5px',
            background: `linear-gradient(90deg, ${hexPrimary}, ${rgbPrimaryFade}, transparent 50%)`
          }

        case 'left':
          return {
            left: 0,
            bottom: 0,
            width: '5px',
            height: 0,
            background: `linear-gradient(180deg, ${hexPrimary}, ${rgbPrimaryFade}, transparent 70%)`
          }

        default:
          return null;
      }
    }, [direction, hexPrimary, rgbPrimaryFade]
  );

  return {
    position: 'absolute',
    borderRadius: '100vmax',
    ...styles,
    ...(
      !noNeonLights && {
        animation
      }
    ),
    ...(
      !!stopAnimation && {
        'animationPlayState': 'paused'
      }
    ),
  }
});

const NeonText = styled(Typography, {
  shouldForwardProp: (prop) => (prop !== 'stopAnimation' && prop !== 'noNeonLights')
})(({ theme, stopAnimation = false, noNeonLights = false }) => {

  const neonAnimation = useMemo(
    () => {
      return keyframes`
        0%, 100% {
          text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 1px 1px 0.1vw #806914;
          color: #FED128;
        }
        50% {
          text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 1px 1px 0.1vw #40340a;
          color: #806914;
        }
      `;
    }, []
  );

  return {
    color: '#FB4264',
    textShadow: '0 0 4px #F40A35',
    fontFamily: 'Neon',
    ...(
      !noNeonLights && {
        animation: `${neonAnimation} 1s ease infinite`,
      }
    ),
    ...(
      !!stopAnimation && {
        'animationPlayState': 'paused'
      }
    ),
    '&:hover': {
      'animationPlayState': 'paused'
    }
  };
});

const FluxText = styled(Typography, {
  shouldForwardProp: (prop) => (prop !== 'stopAnimation' && prop !== 'noNeonLights')
})(({ theme, stopAnimation = false, noNeonLights = false }) => {

  const fluxAnimation = useMemo(
    () => {
      return keyframes`
        0%, 100% {
          text-shadow: 0 0 1vw #1041FF, 0 0 2vw #1041FF, 0 0 7vw #1041FF, 0 0 7vw #1041FF, 0 0 .4vw #8BFDFE, 1px 1px .1vw #147280;
          color: #28D7FE;
        }
        50% {
          text-shadow: 0 0 .5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180, 0 0 5vw #082180, 0 0 .2vw #082180, 1px 1px .1vw #0A3940;
          color: #146C80;
        }
      `;
    }, []
  );

  return {
    color: '#426DFB',
    textShadow: '0 0 4px #2356FF',
    fontFamily: 'Neon',
    ...(
      !noNeonLights && {
        animation: `${fluxAnimation} 2s linear infinite`,
      }
    ),
    ...(
      !!stopAnimation && {
        'animationPlayState': 'paused'
      }
    ),
    '&:hover': {
      'animationPlayState': 'paused'
    }
  };
});


export default function NeonPanel(props) {
  const { stopAnimation = false, noNeonLights = false } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{
      position: 'relative',
      border: `1px solid ${theme.palette.primary.light}`,
      bgcolor: '#223',
      p: 2,
      textAlign: 'center'
    }}>
      <SpanAnimation component='span' direction='top' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <SpanAnimation component='span' direction='right' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <SpanAnimation component='span' direction='bottom' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <SpanAnimation component='span' direction='left' stopAnimation={stopAnimation} noNeonLights={noNeonLights} />
      <NeonText variant='h4' stopAnimation={stopAnimation} noNeonLights={noNeonLights}>
        {t('Lucky Wheel')}
      </NeonText>
      <FluxText variant='h5' stopAnimation={stopAnimation} noNeonLights={noNeonLights}>
        {t('Spin And Win')}
      </FluxText>
    </Box>
  );
}
