import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton';
import axios from 'services/axios';
import { Buffer } from 'buffer';

export default function ProtectedImage(props) {
  const { assetId, name, thumbnail = false, sx = {}, ...rest } = props;
  const [ image, setImage ] = useState(null);

  useEffect(() => {
    if (!assetId) return;

    let isMounted = true;

    async function getImage() {
      try {
        let requestUrl = `/asset/${assetId}`;
        if (thumbnail) requestUrl += '?variant=thumbnail';

        const response = await axios.get(requestUrl, {
          responseType: 'arraybuffer',
          transformResponse: (data) => {
            return Buffer.from(data, 'binary').toString('base64');
          }
        });

        const { data: dataBase64, headers } = response;
        const { 'content-type': mimeType } = headers;
        const imageData = `data:${mimeType};base64,${dataBase64}`;

        if (isMounted) setImage(imageData);
      } catch (err) {
        console.error(err);
      }
    }

    getImage();

    return () => {
      isMounted = false;
    }
  }, [assetId, thumbnail]);

  if (!image) return null;

  if (!image) return <Skeleton sx={sx} pulse='wave' variant='rectangular' width={100} />
  return <Box sx={sx} component='img' alt={name} src={image} {...rest} />
}
