import React, { Suspense, useMemo } from 'react';
import GlobalMessageContextProvider from 'features/context/GlobalMessageContext';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import Loader from 'features/loader/Loader';
import RequireAuth from 'features/login/RequireAuth';
import CommonProvider from 'features/context/CommonProvider';
import SupportProvider from 'features/context/SupportProvider';
import GuestProvider from 'features/context/GuestProvider';
import NewsProvider from 'features/context/NewsProvider';
import AnnouncementProvider from 'features/context/AnnouncementProvider';
import { useUserConfig } from 'hooks/useUserConfig';
import { SnackbarProvider } from 'notistack'
import { get, set } from 'lodash';
import CheckAppVersion from 'features/checkAppVersion/CheckAppVersion';

// Pages
import Login from 'features/login/Login';
import Layout from 'features/layout/Layout';
import Home from 'features/home/Home';
import News from 'features/news/News';
import Wallet from 'features/wallet/Wallet';
import Games from 'features/games/Games';
import Game from 'features/games/Game';
import Deposit from 'features/deposit/Deposit';
import Withdrawal from 'features/withdrawal/Withdrawal';
import Redeem from 'features/redeem/Redeem';
import LuckyDraw from 'features/luckyDraw/LuckyDraw';
import WalletHistory from 'features/wallet/History';
import Referral from 'features/referral/Referral';
import Contact from 'features/contact/Contact';
import Account from 'features/account/Account';
import Settings from 'features/settings/Settings';
import IPUnblock from 'features/ipUnblock/IPUnblock';
import SocialChannels from 'features/socialChannels/SocialChannels';
import GameMenu from 'features/gameMenu/GameMenu';

// Themes
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from './themes/dark';
import defaultTheme from './themes/cw';
import redTheme from './themes/red';
import greenTheme from './themes/green';
import blueTheme from './themes/blue';
import pinkTheme from './themes/pink';
import CssBaseline from '@mui/material/CssBaseline';

// Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const { darkMode, themeName } = useUserConfig();

  const selectedTheme = useMemo(
    () => {
      const defaultGameButtonVariant = get(defaultTheme, 'custom.gameButtonVariant', 'rotary');
      let ret = null;

      if (!!darkMode) {
        ret = darkTheme;
      } else {
        switch (themeName) {
          case 'red':
            ret = redTheme;
            break;

          case 'green':
            ret = greenTheme;
            break;

          case 'blue':
            ret = blueTheme;
            break;

          case 'pink':
            ret = pinkTheme;
            break;

          default:
            ret = defaultTheme;
        }
      }

      set(ret, 'custom.gameButtonVariant', defaultGameButtonVariant);
      return ret;
    }, [darkMode, themeName]
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/unblock" element={<IPUnblock />}>
          <Route path="/unblock/:unblockCode" element={<IPUnblock />} />
        </Route>
        <Route path="/login" element={
          <GuestProvider>
            <SupportProvider>
              <AnnouncementProvider>
                <Login />
              </AnnouncementProvider>
            </SupportProvider>
          </GuestProvider>
        }>
          <Route path="/login/:token" element={
            <GuestProvider>
              <SupportProvider>
                <AnnouncementProvider>
                  <Login />
                </AnnouncementProvider>
              </SupportProvider>
            </GuestProvider>
          } />
        </Route>
        <Route path="/" element={
          <RequireAuth>
            <CommonProvider>
              <NewsProvider>
                <AnnouncementProvider>
                  <Layout />
                </AnnouncementProvider>
              </NewsProvider>
            </CommonProvider>
          </RequireAuth>
        }>
          <Route index element={<Home />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="wallet/deposit">
            <Route path="" element={<Deposit />} />
            <Route path=":amount/:rewardFlag" element={<Deposit />} />
            <Route path=":amount/:rewardFlag/:bankId" element={<Deposit />} />
          </Route>
          <Route path="wallet/withdrawal" element={<Withdrawal />} />
          <Route path="wallet/history" element={<WalletHistory />} />
          <Route path="games" element={<Games />} />
          <Route path="games/:gameType" element={<Game />} />
          <Route path="games/:gameType/menu" element={<GameMenu />} />
          <Route path="redeem" element={<Redeem />} />
          <Route path="free" element={<LuckyDraw />} />
          <Route path="referral" element={
            <SupportProvider>
              <Referral />
            </SupportProvider>
          } />
          <Route path="contact" element={
            <SupportProvider>
              <Contact />
            </SupportProvider>
          } />
          <Route path="social-channels" element={<SocialChannels />} />
          <Route path="account" element={<Account />} />
          <Route path="settings" element={<Settings />} />
          <Route path="news/:newsId" element={<News />} />
          <Route path="*" element={<Navigate to='/' replace />} />
        </Route>
      </>
    )
  );

  return (
    <ThemeProvider theme={selectedTheme}>
      <CssBaseline />
      <Suspense fallback={<Loader open={true} />}>
        <SnackbarProvider maxSnack={1}>
          <GlobalMessageContextProvider>
            <CheckAppVersion />
            <RouterProvider router={router} />
          </GlobalMessageContextProvider>
        </SnackbarProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
