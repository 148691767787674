import React, { useContext, useMemo, useState, useEffect, useRef, useCallback } from "react";
import CommonContext from 'features/context/commonContext';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
  SearchTwoTone as SearchIcon,
  ClearTwoTone as ClearIcon,
  SortByAlphaTwoTone as SortByAlphaIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { orderBy, throttle, shuffle } from 'lodash';
import { useVirtualizer } from "@tanstack/react-virtual";
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import isIOS from 'utils/isIOS.js';
import { nanoid } from "nanoid";
import PragmaticPlayDga from 'features/gameMenu/extra/PragmaticPlayDga';
import {
  amber,
  lime,
  red,
} from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import useQuery from 'hooks/useQuery';
import pako from 'pako';

const blinker = keyframes`
  50% {
    opacity: 0.5;
  }
`;

function RectCardMedia({ image, alt }) {
  //const ref = useRef();

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (ref.current) {
  //       //const containerWidth = ref.current.offsetWidth;
  //       //ref.current.style.height = `${containerWidth}px`;
  //       ref.current.style.height = 'auto';
  //     }
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // const [ imageLoaded, setImageLoaded ] = useState(false);
  // const imageObj = new Image();

  // imageObj.src = image;

  // imageObj.onload = () => {
  //   setImageLoaded(true);
  // }

  // if (!imageLoaded) {
  //   return (
  //     <CardContent>
  //       <Skeleton variant="rectangular" width="100%" height={180} />
  //     </CardContent>
  //   );
  // }

  return (
    <CardMedia
      component="img"
      //ref={ref}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
      image={image}
      alt={alt}
    />
  );
}

const DEFAULT_SELECTED_GAME = {
  code: null,
  name: null,
  logoUrl: null,
  type: null,
  jackpot: null,
};

function cacheGameList(gameType, gameList, deflate = false) {
  const storageKey = `gameList-${gameType}`;
  const now = new Date().getTime();
  const ttl = now + 60000 * 60; // 1 hour

  const cacheData = {
    ttl,
    deflate,
    data: gameList,
  };

  const stringifiedCacheData = JSON.stringify(cacheData);

  localStorage.setItem(storageKey, stringifiedCacheData);
}

function getCachedGameList(gameType) {
  const storageKey = `gameList-${gameType}`;
  const cachedGameList = localStorage.getItem(storageKey);

  if (!cachedGameList) return null;

  const parsedGameList = JSON.parse(cachedGameList);
  const { ttl, data, deflate = false } = parsedGameList;

  const now = new Date().getTime();

  if (now < ttl) {
    if (deflate) {
      const compressedUint8Array = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
      const inflatedData = pako.inflate(compressedUint8Array, { to: 'string' });
      const parsedInflatedData = JSON.parse(inflatedData);
      return parsedInflatedData;
    }
    return data;
  } else {
    localStorage.removeItem(storageKey);
    return null;
  }
}

const ApiMenu = ({ gameType, recentPayouts = [] }) => {
  const { gameIds, gameIdsReady } = useContext(CommonContext);
  const { t } = useTranslation();
  const [ selectedGameType, setSelectedGameType ] = useState('');
  const [ searchKeyword, setSearchKeyword ] = useState('');
  const [ searchKeywordThrottled, setSearchKeywordThrottled ] = useState('');
  const [ gameMenuOrderMode, setGameMenuOrderMode ] = useState('default'); // default, nameAsc, nameDesc, shuffle
  const [ filteredGames, setFilteredGames ] = useState([]);
  const [ selectedGame, setSelectedGame ] = useState(DEFAULT_SELECTED_GAME);
  const parentRef = useRef(null);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ reqCorrelationId, setReqCorrelationId ] = useState(null);
  const [ gameList, setGameList ] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [ playtechIntegrationScriptUrl, setPlaytechIntegrationScriptUrl ] = useState(null);
  const [ playtechIntegrationState, setPlaytechIntegrationState ] = useState('idle');
  const [ playtechToken, setPlaytechToken ] = useState(null);
  const [ requestState, setRequestState ] = useState('idle');
  const [ showSearch, setShowSearch ] = useState(false);
  const theme = useTheme();
  const query = useQuery();

  const selectedGameTypeFromQuery = useMemo(
    () => {
      const filter = query.get('filter') || '';
      return filter;
    }, [query]
  );

  useEffect(() => {
    if (!selectedGameTypeFromQuery) return;
    setSelectedGameType(selectedGameTypeFromQuery);
  }, [selectedGameTypeFromQuery]);

  const totalOffsetHeight = useMemo(
    () => {
      const announcement = document.getElementById('announcement');
      const appBar = document.getElementById('appBar');
      const bottomNav = document.getElementById('bottomNav');
      const announcementHeight = announcement?.offsetHeight || 0;
      const appBarHeight = appBar?.offsetHeight || 0;
      const bottomNavHeight = bottomNav?.offsetHeight || 0;
      const gameTypeTabHeight = 48;
      const ret = announcementHeight + appBarHeight + bottomNavHeight + gameTypeTabHeight + 40;
      return ret;
    }, []
  );

  const isPragmaticLive = useMemo(
    () => {
      return gameType === 'pragmatic-play-api' && selectedGameType === 'live';
    }, [gameType, selectedGameType]
  );

  const isPgSoft = useMemo(
    () => {
      return gameType === 'amb-pgsoft-api';
    }, [gameType]
  );

  const virtualizerConfig = useMemo(
    () => {
      let lanes = isPragmaticLive ? 1 : 3;
      let overscan = isPragmaticLive ? 3 : 5;

      if (isPragmaticLive) {
        if (isMediumScreen) {
          lanes = 2;
          overscan = 4;
        } else if (isLargeScreen) {
          lanes = 3;
          overscan = 5;
        }
      } else {
        if (isMediumScreen) {
          lanes = 4;
          overscan = 6;
        } else if (isLargeScreen) {
          lanes = 5;
          overscan = 7;
        }
      }

      const width = 100 / lanes;
      const left = lanes === 1 ? 0 : width;

      return {
        lanes,
        width,
        left,
        overscan,
      }
    }, [isPragmaticLive, isMediumScreen, isLargeScreen]
  );

  useEffect(() => {
    if (!isPragmaticLive) return;

    let isMounted = true;

    const service = feathers.service('pragmatic-play-dga');

    const onCreated = (data) => {
      if (!isMounted) return;

      const { code } = data;
      const game = gameList.find((game) => game.code === code);

      if (!game) {
        setGameList((prevGameList) => [...prevGameList, data]);
      } else {
        setGameList((prevGameList) => {
          const ret = prevGameList.map((game) => {
            if (game.code === code) {
              return data;
            } else {
              return game;
            }
          });
          return ret;
        });
      }
    };

    service.on('created', onCreated);

    return () => {
      isMounted = false;
      service.removeListener('created', onCreated);
    };
  }, [gameList, isPragmaticLive]);

  // Call game-ids with action 'gameList'
  useEffect(() => {
    const service = feathers.service('game-ids');

    async function fetchGameList() {
      const cachedGameList = getCachedGameList(gameType);

      if (cachedGameList) {
        setGameList(cachedGameList);
        return;
      }

      try {
        setRequestState('gameList');
        await service.find({
          query: {
            __action__: 'gameList',
            gameType,
            deflate: true,
          }
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setRequestState('idle');
      }
    }

    fetchGameList();
  }, [gameType, setGlobalErrorMessage]);

  const gameCodesWithRecentPayouts = useMemo(
    () => {
      return recentPayouts.reduce((acc, cur) => {
        const { gameCode } = cur;
        if (gameCode && !acc.includes(gameCode)) {
          acc.push(gameCode);
        }
        return acc;
      }, []);
    }, [recentPayouts]
  );

  const gameTypesAndCustomGroup = useMemo(
    () => {
      if (!gameList?.length) return [];

      const [gameTypes, customGroup] = gameList.reduce((acc, cur) => {
        const { type, customGroup, jackpot = false } = cur;
        if (!acc[0].includes(type)) {
          acc[0].push(type);
        }
        if (customGroup && !acc[1].includes(customGroup)) {
          acc[1].push(customGroup);
        }
        if (jackpot && !acc[0].includes('jackpot')) {
          acc[0].push('jackpot');
        }
        return acc;
      }, [[], []]);

      const sortedGameTypes = gameTypes.sort((a, b) => {
        if (a === 'live') return -1;
        if (b === 'live') return 1;
        if (a === 'slot') return -1;
        if (b === 'slot') return 1;
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }) || [];

      const sortedCustomGroup = customGroup.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }) || [];

      return [sortedGameTypes, sortedCustomGroup];
    }, [gameList]
  );

  const availableGameTypes = useMemo(
    () => {
      return gameTypesAndCustomGroup[0] || [];
    }, [gameTypesAndCustomGroup]
  );

  const customGroup = useMemo(
    () => {
      return gameTypesAndCustomGroup[1] || [];
    }, [gameTypesAndCustomGroup]
  );

  useEffect(
    () => {
      let ret = [];

      if (customGroup.includes(selectedGameType)) {
        ret = gameList.filter((game) => game.customGroup === selectedGameType);
      } else if (selectedGameType === 'jackpot') {
        ret = gameList.filter((game) => game.jackpot === true);
      } else {
        ret = gameList.filter((game) => game.type === selectedGameType);
      }

      if (searchKeywordThrottled) {
        ret = ret.filter((game) => {
          const { name = '', description = '' } = game;
          const nameMatch = name.toLowerCase().includes(searchKeywordThrottled.toLowerCase());
          const descriptionMatch = description.toLowerCase().includes(searchKeywordThrottled.toLowerCase());
          return nameMatch || descriptionMatch;
        });
      }

      switch (gameMenuOrderMode?.split('_')[0] || 'default') {
        case 'nameAsc':
          ret = orderBy(ret, ['name'], ['asc']);
          break;

        case 'nameDesc':
          ret = orderBy(ret, ['name'], ['desc']);
          break;

        case 'shuffle':
          ret = shuffle(ret);
          break;

        default:
          ret = orderBy(ret, ['weight'], ['asc']);
          break;
      }

      ret = ret.map((game) => {
        const { code: gameCode, meta: originalMeta } = game;
        const meta = {
          ...originalMeta,
        };

        if (gameCodesWithRecentPayouts.includes(gameCode)) {
          meta.isRecentPayout = true;
        }

        return {
          ...game,
          meta,
        };
      });

      ret = orderBy(ret, ['meta.isRecentPayout'], ['asc']);

      setFilteredGames(ret);
    }, [selectedGameType, gameMenuOrderMode, gameList, searchKeywordThrottled, gameCodesWithRecentPayouts, customGroup]
  );

  useEffect(
    () => {
      const throttleSearchKeyword = throttle(
        () => {
          setSearchKeywordThrottled(searchKeyword);
        }, 1000
      );
      throttleSearchKeyword();
    }, [searchKeyword, selectedGameType, gameList]
  );

  useEffect(() => {
    if (!!selectedGameType || !availableGameTypes?.length) return;

    if (availableGameTypes.includes('slot')) {
      setSelectedGameType('slot');
    } else if (availableGameTypes.includes('live')) {
      setSelectedGameType('live');
    } else {
      setSelectedGameType(availableGameTypes[0]);
    }
  }, [selectedGameType, availableGameTypes]);

  const rowVirtualizer = useVirtualizer({
    count: filteredGames.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 150,
    overscan: virtualizerConfig.overscan,
    lanes: virtualizerConfig.lanes,
    horizontal: false,
  });

  const gameId = useMemo(
    () => {
      if (!gameIdsReady) return null;
      return gameIds.find((gameId) => gameId.gameType === gameType);
    }, [gameIds, gameIdsReady, gameType]
  );

  const gameUsername = useMemo(
    () => {
      if (!gameId) return '';
      return gameId?.gameId;
    }, [gameId]
  );

  const gamePassword = useMemo(
    () => {
      if (!gameId) return '';
      return gameId?.gamePassword;
    }, [gameId]
  );

  const isLoadingGameMenu = useMemo(
    () => {
      return requestState === 'gameList';
    }, [requestState]
  );

  const isStartingGame = useMemo(
    () => {
      return requestState === 'startGame';
    }, [requestState]
  );

  const isStartGameDisabled = useMemo(
    () => {
      if (gameType === 'playtech-api' && playtechToken === null) return true;
      return false;
    }, [gameType, playtechToken]
  );

  const handleGameClick = useCallback(
    (game) => (event) => {
      event.preventDefault();
      setSelectedGame(game);
    }, []
  );

  const handleGameReset = useCallback(
    (event) => {
      event.preventDefault();
      setSelectedGame(DEFAULT_SELECTED_GAME);
    }, []
  );

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('game-ids');

    const onTaskProcessed = (data) => {
      const { action } = data;

      switch (action) {
        case 'startGame':
          onStartGame(data);
          break;

        case 'gameList':
          onGameListReceived(data);
          break;

        default:
          break;
      }
    };

    const onStartGame = async (data) => {
      const { correlationId, gameUrl, success } = data;

      // Ignore if correlationId (current request) is not the same as the one returned from server (possibly timeout)
      if (correlationId !== reqCorrelationId) return;

      setReqCorrelationId(null);
      setRequestState('idle');

      if (!success) {
        setGlobalErrorMessage({ err: new Error('Failed to start game') });
        return;
      }

      if (gameUrl && isMounted) {
        const target = isIOS() ? '_self' : '_blank';
        window.open(gameUrl, target);
      }
    };

    const onGameListReceived = (data) => {
      const { deflate = false, gameType, integrationScriptUrl = null, gameList: rawGameList } = data;
      let gameList;

      if (!deflate) {
        gameList = rawGameList;
      } else {
        const compressedUint8Array = Uint8Array.from(atob(rawGameList), (c) => c.charCodeAt(0));
        const inflatedData = pako.inflate(compressedUint8Array, { to: 'string' });
        gameList = JSON.parse(inflatedData);
      }

      cacheGameList(gameType, rawGameList, deflate);

      // Handle playtech-api separately
      if (gameType === 'playtech-api') {
        setPlaytechIntegrationScriptUrl(integrationScriptUrl);
      } else {
        setRequestState('idle');
      }

      if (isMounted) {
        setGameList(gameList);
      }
    };

    const onFailed = (data) => {
      const { __externalCall__ = false } = data;
      if (__externalCall__ && isMounted) {
        const { error } = data;
        setRequestState('idle');
        setGlobalErrorMessage({ err: error });
      }
    }

    service.on('processed', onTaskProcessed);
    service.on('failed', onFailed);

    return () => {
      isMounted = false;
      service.removeListener('processed', onStartGame);
      service.removeListener('failed', onFailed);
    };
  }, [setGlobalErrorMessage, t, reqCorrelationId]);

  /*
    Implement timeout when correlationId is changed
  */
  useEffect(() => {
    let timeout = null;

    if (reqCorrelationId) {
      timeout = setTimeout(() => {
        setReqCorrelationId(null);
        setRequestState('idle');
        setGlobalErrorMessage({ err: new Error('Failed to start game') });
      }, 15000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    }
  }, [reqCorrelationId, t, setGlobalErrorMessage]);

  useEffect(() => {
    if (!playtechIntegrationScriptUrl || playtechIntegrationState !== 'idle') return;

    // check if the script is already loaded
    if (window.iapiLoginAndGetTempToken) {
      setPlaytechIntegrationState('scriptLoaded');
      return;
    }

    const script = document.createElement('script');
    script.src = playtechIntegrationScriptUrl;
    script.async = true;
    document.body.appendChild(script);

    const onLoaded = () => {
      setPlaytechIntegrationState('scriptLoaded');
    }

    script.onload = onLoaded;

    return () => {
      script.onload = null;
      document.body.removeChild(script);
    }

  }, [playtechIntegrationScriptUrl, playtechIntegrationState]);

  useEffect(() => {
    if (playtechIntegrationState !== 'scriptLoaded') return;

    const { iapiSetCallout, iapiLoginAndGetTempToken, iapiSetClientType, iapiSetClientPlatform } = window;

    const calloutLoginAndGetTempToken = (response) => {
      const { errorCode, perm } = response;

      if (errorCode) {
        setPlaytechIntegrationState('loginFailed');
        return;
      } else {
        setPlaytechToken(perm);
        setPlaytechIntegrationState('loginSuccess');
      }
    }

    const clientPlatform = isSmallScreen ? 'mobile' : 'web';
    const capitalizedGameId = gameUsername?.toUpperCase();

    iapiSetCallout('LoginAndGetTempToken', calloutLoginAndGetTempToken);
    iapiSetClientType('casino');
    iapiSetClientPlatform(clientPlatform);
    iapiLoginAndGetTempToken(capitalizedGameId, gamePassword, 1, 'en');

    return () => {
      iapiSetCallout('LoginAndGetTempToken', null);
    }
  }, [playtechIntegrationScriptUrl, playtechIntegrationState, isSmallScreen, gameUsername, gamePassword]);

  useEffect(() => {
    if (playtechIntegrationState !== 'loginSuccess') return;

    setRequestState('idle');
    setPlaytechIntegrationState('idle');
    setPlaytechIntegrationScriptUrl(null);
  }, [playtechIntegrationState]);

  useEffect(() => {
    if (playtechIntegrationState !== 'loginFailed') return;

    setRequestState('idle');
    setPlaytechIntegrationState('idle');
    setPlaytechIntegrationScriptUrl(null);
    setPlaytechToken(null);
    setGlobalErrorMessage({ err: new Error('Failed to login game') });
  }, [playtechIntegrationState, t, setGlobalErrorMessage]);

  const handleStartGame = useCallback(
    (selectedGame, realMode = true) => async (event) => {
      event.preventDefault();

      try {
        const { code: gameCode, tableCode, playUrl } = selectedGame;

        const corrId = nanoid();
        setRequestState('startGame');
        setReqCorrelationId(corrId);
        const lobbyUrl = window.location.href;

        await feathers.service('game-ids').find({
          query: {
            correlationId: corrId,
            __action__: 'startGame',
            gameType,
            gameCode,
            tableCode,
            realMode,
            playUrl,
            lobbyUrl,
            isMobile: isSmallScreen,
          }
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setRequestState('idle');
      }
    }, [gameType, setGlobalErrorMessage, isSmallScreen]
  );

  const isRequestFree = useMemo(
    () => {
      return requestState === 'idle';
    }, [requestState]
  );

  const handleSearchClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowSearch((prevShowSearch) => !prevShowSearch);
    }, []
  );

  const cardTitle = useMemo(
    () => {
      if (!selectedGameType) return '- (0)';

      const filteredGamesLength = filteredGames?.length || 0;

      if (customGroup.includes(selectedGameType)) {
        return `${selectedGameType} (${filteredGamesLength})`;
      }

      const translatedGameType = t(`gameMenu.${selectedGameType}`);
      return `${translatedGameType} (${filteredGamesLength})`;
    }, [selectedGameType, filteredGames, t, customGroup]
  );

  if (!gameUsername) {
    return (
      <Alert severity="error">
        {t('gameMenu.gameIdNotFound')}
      </Alert>
    );
  }

  return (
    <>
      <Dialog
        open={selectedGame?.code !== null}
        onClose={handleGameReset}
      >
        <DialogTitle onClose={handleGameReset}>
          {selectedGame.name}
        </DialogTitle>
        <DialogContent>
          {
            gameType === 'pragmatic-play-api' && selectedGame.type === 'live' ?
            <PragmaticPlayDga game={selectedGame}>
              <RectCardMedia
                image={selectedGame.logoUrl}
                alt={selectedGame.name}
              />
            </PragmaticPlayDga> :
              <RectCardMedia
                image={selectedGame.logoUrl}
                alt={selectedGame.name}
              />
          }
          <Box sx={{ mt: 2 }}>
            <ButtonGroup
              size="large"
              fullWidth
            >
              <Button
                disabled={!isRequestFree || isStartGameDisabled}
                variant="contained"
                color="success"
                onClick={handleStartGame(selectedGame)}
                sx={{
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                }}
              >
                {t('gameMenu.play')}
              </Button>
              {
                selectedGame.hasDemo &&
                <Button
                  disabled={!isRequestFree}
                  variant='outlined'
                  color="success"
                  onClick={handleStartGame(selectedGame, false)}
                  sx={{
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                  }}
                >
                  {t('gameMenu.try')}
                </Button>
              }
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGameReset}>
            {t('gameMenu.close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
        <Card>
          <CardHeader
            disableTypography
            title={
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {
                  (availableGameTypes.length + customGroup.length) > 1 &&
                  <PopupState variant="popover" popupId="gameTypeMenu">
                    {(popupState) => (
                      <>
                        <Button
                          {...bindTrigger(popupState)}
                          variant="outlined"
                          color="success"
                          endIcon={<KeyboardArrowDownIcon />}
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cardTitle}
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          {
                            availableGameTypes.map((gameType) => (
                              <MenuItem
                                key={gameType}
                                onClick={() => {
                                  setSelectedGameType(gameType);
                                  popupState.close();
                                }}
                                sx={{
                                  ...selectedGameType === gameType && {
                                    color: 'success.main',
                                    fontWeight: 'bold',
                                  },
                                }}
                              >
                                {t(`gameMenu.${gameType}`)}
                              </MenuItem>
                            ))
                          }
                          {
                            customGroup.map((customGroup) => (
                              <MenuItem
                                key={customGroup}
                                onClick={() => {
                                  setSelectedGameType(customGroup);
                                  popupState.close();
                                }}
                                sx={{
                                  ...selectedGameType === customGroup && {
                                    color: 'success.main',
                                    fontWeight: 'bold',
                                  },
                                }}
                              >
                                {customGroup}
                              </MenuItem>
                            ))
                          }
                        </Menu>
                      </>
                    )}
                  </PopupState>
                }
                <Box sx={{
                  display: (isLoadingGameMenu || isStartingGame) ? 'flex' : 'none',
                  ml: 1,
                  alignItems: 'center',
                }}>
                  <CircularProgress size={20}/>
                </Box>
              </Box>
            }
            action={
              <Stack spacing={1} direction='row'>
              <PopupState variant="popover" popupId="sortMenu">
                {(popupState) => (
                  <>
                    <IconButton {...bindTrigger(popupState)}>
                      <SortByAlphaIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        sx={{
                          ...gameMenuOrderMode === 'default' && {
                            color: 'success.main',
                            fontWeight: 'bold',
                          },
                        }}
                        onClick={() => {
                          setGameMenuOrderMode('default');
                          popupState.close();
                        }}
                      >
                        {t('gameMenu.sortGamesByDefault')}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          ...gameMenuOrderMode === 'nameAsc' && {
                            color: 'success.main',
                            fontWeight: 'bold',
                          },
                        }}
                        onClick={() => {
                          setGameMenuOrderMode('nameAsc');
                          popupState.close();
                        }}
                      >
                        {t('gameMenu.sortGamesAlphabeticallyAsc')}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          ...gameMenuOrderMode === 'nameDesc' && {
                            color: 'success.main',
                            fontWeight: 'bold',
                          },
                        }}
                        onClick={() => {
                          setGameMenuOrderMode('nameDesc');
                          popupState.close();
                        }}
                      >
                        {t('gameMenu.sortGamesAlphabeticallyDesc')}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          ...gameMenuOrderMode?.split('_')[0] === 'shuffle' && {
                            color: 'success.main',
                            fontWeight: 'bold',
                          },
                        }}
                        onClick={() => {
                          setGameMenuOrderMode('shuffle_' + nanoid());
                          popupState.close();
                        }}
                      >
                        {t('gameMenu.shuffleGames')}
                      </MenuItem>

                    </Menu>
                  </>
                )}
              </PopupState>
              <IconButton onClick={handleSearchClick}>
                <SearchIcon
                  sx={{
                    color: showSearch ? 'success.main' : 'text.primary',
                  }}
                />
              </IconButton>
              </Stack>
            }
          />
          <Divider />
          {
              showSearch &&
              <CardActions>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    placeholder={t('gameMenu.searchPlaceholder')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setSearchKeyword('')}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </CardActions>
            }
        </Card>
      </Box>
      <Box
        id="virtualizer-container"
        ref={parentRef}
        sx={{
          mt: 1,
          height: `calc(100vh - ${totalOffsetHeight}px)`,
          maxWidth: {
            sm: 'sm',
            md: 'xl',
          },
          mx: 'auto',
          overflow: 'auto',
        }}
      >
        <Box
          component="div"
          sx={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {
            rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const game = filteredGames[virtualRow.index];
              const { code: gameCode } = game || {};
              const key = `${gameCode}-${virtualRow.index}`;
              const { isNew = false, isHot = false, isRecentPayout = false } = game?.meta || {};

              const bgColor = isRecentPayout ? red[600] : (isNew ? lime[500] : (isHot ? amber[700] : theme.palette.background.default));
              const contrastTextColor = theme.palette.getContrastText(bgColor);
              const extraLabel = isRecentPayout ? 'win' : (isNew ? 'new' : (isHot ? 'hot' : ''));

              return (
                <Box
                  onClick={handleGameClick(game)}
                  component="div"
                  key={key}
                  data-index={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: `${virtualRow.lane * virtualizerConfig.left}%`,
                    width: `${virtualizerConfig.width}%`,
                    //height: `${virtualRow.size}px`,
                    //height: `${rows[virtualRow.index]}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ...(isPgSoft && {
                      bgcolor: 'white',
                      borderRadius: 1,
                      m: .5,
                    }),
                  }}>
                    {
                      !!extraLabel &&
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: 1,
                        zIndex: 1,
                      }}>
                        <Box
                          sx={{
                            bgcolor: bgColor,
                            position: 'relative',
                            borderRadius: 1,
                            margin: {
                              xs: 0.5,
                              sm: 1,
                            }
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              padding: 0.5,
                              fontWeight: 'bold',
                              color: contrastTextColor,
                              textAlign: 'center',
                              animation: `${blinker} 1s linear 5`,
                            }}
                          >
                            {t(`gameMenu.${extraLabel}`)}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    {
                      gameType === 'pragmatic-play-api' && game.type === 'live' ?
                        <PragmaticPlayDga game={game}>
                          <Box
                            component='img'
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              p: 0.25,
                              borderRadius: 1,
                            }}
                            src={game.logoUrl}
                            alt={game.name}
                          />
                        </PragmaticPlayDga>
                        :
                        <Box
                          component='img'
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            p: 0.25,
                            borderRadius: 1,
                          }}
                          src={game.logoUrl}
                          alt={game.name}
                        />
                    }
                    {
                      isPgSoft &&
                        <Box sx={{
                          position: 'relative',
                          zIndex: 1,
                          py: 1,
                          width: '100%',
                          borderRadius: 1,
                        }}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mx: 1,
                          }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textTransform: "uppercase",
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                overflowWrap: 'anywhere',
                                color: them => them.palette.getContrastText('rgba(255, 255, 255, 1.0)'),
                              }}
                            >
                              {game?.name}
                            </Typography>
                          </Box>
                        </Box>
                    }
                  </Box>
                </Box>
              )
            }
          )}
        </Box>
      </Box>
    </>
  );
}

export default ApiMenu;
