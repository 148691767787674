import React, { useMemo, useContext } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useAuth } from 'hooks/useAuth';
import { useUserConfig } from 'hooks/useUserConfig';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import flatten from 'flat';
import CommonContext from 'features/context/commonContext';
import { lookups as lookupLocales } from 'lookups/locales';

const THEME_OPTIONS = [
  { key: 'default', label: 'Default' },
  { key: 'red', label: 'Red' },
  { key: 'green', label: 'Green' },
  { key: 'blue', label: 'Blue' },
  { key: 'pink', label: 'Pink' },
];

export default function Settings(props) {
  const { user, update: updateUser } = useAuth();
  const userId = get(user, '_id');
  const supportedLocales = get(user, 'supportedLocales', ['en']);
  const { t } = useTranslation();
  const { companySetting } = useContext(CommonContext);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const enableHideLiveTransaction = useMemo(
    () => {
      const hideLiveTransaction = companySetting?.walletConfig?.hideLiveTransaction || false;
      return !hideLiveTransaction;
    }, [companySetting]
  );

  const { lang, themeName, hideAnnouncement, hideLiveTransaction, compactAppBar, noNeonLights } = useUserConfig();

  const data = useMemo(
    () => {
      return {
        lang,
        settings: {
          themeName,
          hideAnnouncement,
          ...(
            enableHideLiveTransaction && {
              hideLiveTransaction
            }
          ),
          compactAppBar,
          noNeonLights,
        }
      }
    }, [lang, themeName, hideAnnouncement, hideLiveTransaction, compactAppBar, noNeonLights, enableHideLiveTransaction]
  );

  const settingsSchema = Yup.object().shape({
    lang: Yup.string().required(t("Required")).matches(new RegExp(`(${supportedLocales.join('|')})`)),
    settings: Yup.object().shape({
      themeName: Yup.string().required(t("Required")),
      hideAnnouncement: Yup.bool().required(t("Required")),
      ...(
        enableHideLiveTransaction && {
          hideLiveTransaction: Yup.bool().required(t("Required")),
        }
      ),
      compactAppBar: Yup.bool().required(t("Required")),
      noNeonLights: Yup.bool().required(t("Required")),
    })
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: settingsSchema,
    onSubmit: async (values) => {
      const flattenVal = flatten(values, { safe: true });
      try {
        const savedUser = await feathers.service('users').patch(userId,{
          $set: {
            ...flattenVal,
            'settings.darkMode': false
          }
        });
        updateUser(savedUser);
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formik.isSubmitting) return;
    formik.submitForm();
  };

  const handleBooleanChanged = (fieldName) => (event) => {
    const val = get(event, 'target.checked', false);
    formik.setFieldValue(fieldName, val);
  };

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardHeader disableTypography
              title={
                <Typography variant='h6'>
                  {t('Settings')}
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    id="lang"
                    name="lang"
                    label={t('Language')}
                    value={formik.values.lang}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.lang && Boolean(formik.errors.lang)}
                    helperText={formik.touched.lang && formik.errors.lang}
                    SelectProps={{
                      native: true,
                    }}
                  >
                  {
                    supportedLocales.map((locale) => (
                      <option key={locale} value={locale}>
                        {lookupLocales[locale]}
                      </option>
                    ))
                  }
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    id="settings.themeName"
                    name="settings.themeName"
                    label={t('Theme')}
                    value={formik.values.settings.themeName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched?.settings?.themeName && Boolean(formik.errors?.settings?.themeName)}
                    helperText={formik.touched?.settings?.themeName && formik.errors?.settings?.themeName}
                    SelectProps={{
                      native: true,
                    }}
                  >
                  {
                    THEME_OPTIONS.map((option) => (
                      <option key={option.key} value={option.key}>
                        {t(option.label)}
                      </option>
                    ))
                  }
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 2 }}>
                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          onChange={handleBooleanChanged('settings.hideAnnouncement')}
                          checked={get(formik, 'values.settings.hideAnnouncement', false)}
                        />
                      } label={t('Hide announcement')} />
                    </FormGroup>
                    {
                      enableHideLiveTransaction &&
                      <FormGroup>
                        <FormControlLabel control={
                          <Switch
                            onChange={handleBooleanChanged('settings.hideLiveTransaction')}
                            checked={get(formik, 'values.settings.hideLiveTransaction', false)}
                          />
                        } label={t('Hide live transaction')} />
                      </FormGroup>
                    }
                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          onChange={handleBooleanChanged('settings.compactAppBar')}
                          checked={get(formik, 'values.settings.compactAppBar', false)}
                        />
                      } label={t('Compact app bar')} />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          onChange={handleBooleanChanged('settings.noNeonLights')}
                          checked={get(formik, 'values.settings.noNeonLights', false)}
                        />
                      } label={t('No neon lights')} />
                    </FormGroup>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button variant="contained" onClick={handleSubmit}>
                {t('Save')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
