import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { get } from 'lodash';
import useGameLogo from 'hooks/useGameLogo';

const Section1 = styled('div')(
  ({ isSmallScreen }) => {
    return {
      width: isSmallScreen ? '100px' : '150px',
      height: isSmallScreen ? '100px' : '150px',
      backgroundImage: `url(/images/bg-games/green_mandala/sprite.png)`,
      backgroundPosition: '0 0',
      backgroundSize: 'cover',
    }
  }
);

const Section2 = styled('div')(
  ({ isSmallScreen }) => {
    return {
      width: isSmallScreen ? '100px' : '150px',
      height: isSmallScreen ? '100px' : '150px',
      backgroundImage: `url(/images/bg-games/green_mandala/sprite.png)`,
      backgroundPosition: isSmallScreen ? '-100px 0' : '-150px 0',
      backgroundSize: 'cover',
    }
  }
);

const Section3 = styled('div')(
  ({ isSmallScreen }) => {
    return {
      width: isSmallScreen ? '100px' : '150px',
      height: isSmallScreen ? '100px' : '150px',
      backgroundImage: `url(/images/bg-games/green_mandala/sprite.png)`,
      backgroundPosition: isSmallScreen ? '-201px 0' : '-301px 0',
      backgroundSize: 'cover',
    }
  }
);

const Section4 = styled('div')(
  ({ isSmallScreen }) => {
    return {
      width: isSmallScreen ? '100px' : '150px',
      height: isSmallScreen ? '100px' : '150px',
      backgroundImage: `url(/images/bg-games/green_mandala/sprite.png)`,
      backgroundPosition: isSmallScreen ? '-300px 0' : '-450px 0',
      backgroundSize: 'cover',
    }
  }
);

export default function Games(props) {
  const { isEnabled, gameType, gameBalance, onClick } = props;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const { getGameImagePath } = useGameLogo();

  const isNightTheme = useMemo(
    () => {
      const themeName = get(theme, 'custom.name');
      return themeName === 'night';
    }, [theme]
  );

  const balanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: translate(-50%, -50%) scale(1, 1); }
        25% { transform: translate(-50%, -50%) scale(0.9, 1.1); }
        50% { transform: translate(-50%, -50%) scale(1.1, 0.9); }
        75% { transform: translate(-50%, -50%) scale(0.95, 1.05); }
      `;
    }, []
  );

  const fadeInAnimation = useMemo(
    () => {
      return keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      `;
    }, []
  );


  const spinAnimation = useMemo(
    () => {
      return keyframes`
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      `;
    }, []
  );

  return (
    <Box
      onClick={onClick}
      sx={{
        mb: 1,
        ...(
          !isEnabled && {
            filter: 'grayscale(100%)'
          }
        ),
        position: 'relative',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}>
        <Section1
          isSmallScreen={isSmallScreen}
          sx={{
            ...(
              isNightTheme && {
                filter: 'grayscale(70%)'
              }
            ),
            borderRadius: 2,
            position: 'relative',
          }}
        />
        <Section2
          isSmallScreen={isSmallScreen}
          sx={{
            ...(
              isNightTheme && {
                filter: 'grayscale(70%)'
              }
            ),
            borderRadius: 2,
            position: 'absolute',
            opacity: 0,
            animation: `${fadeInAnimation} 1s ease-in-out forwards`,
            animationDelay: `${Math.floor(Math.random() * 500)}ms`,
          }}
        />
        <Section3
          isSmallScreen={isSmallScreen}
          sx={{
            ...(
              isNightTheme && {
                filter: 'grayscale(70%)'
              }
            ),
            borderRadius: 2,
            position: 'absolute',
            ...(
              isEnabled && {
                animation: `${spinAnimation} ${!!gameBalance ? '10s' : '20s'} infinite linear`,
              }
            )
          }}
        />
        <Section4
          isSmallScreen={isSmallScreen}
          sx={{
            display: isNightTheme ? 'none' : 'block',
            overflow: 'hidden',
            borderRadius: 2,
            position: 'absolute',
          }}
        />
      </Box>
      <Box
        component='img'
        src={getGameImagePath(gameType)}
        alt={`${gameType} logo`}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '5.6rem', sm: '8rem' },
          height: { xs: '5.6rem', sm: '8rem' },
          zIndex: 20,
          ...(
            isEnabled && !!gameBalance && {
              animation: `${balanceAnimation} 0.5s infinite linear`
            }
          ),
        }}
      />
    </Box>
  );
}
