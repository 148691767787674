import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { get, shuffle } from 'lodash';
import useGameLogo from 'hooks/useGameLogo';

export default function Games(props) {
  const { isEnabled, gameType, gameBalance, onClick } = props;
  const theme = useTheme();
  const themeName = get(theme, 'custom.name');
  const { getGameImagePath } = useGameLogo();

  const isDarkPalette = useMemo(
    () => {
      return get(theme, 'palette.mode', 'light') === 'dark' ? true : false;
    }, [theme]
  );

  const bgDir = useMemo(
    () => {
      return isDarkPalette ? 'dark' : 'light';
    }, [isDarkPalette]
  );

  const bgFormat = useMemo(
    () => {
      return isDarkPalette ? 'png' : 'jpg';
    }, [isDarkPalette]
  );

  const balanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: scale(1, 1); }
        25% { transform: scale(0.9, 1.1); }
        50% { transform: scale(1.1, 0.9); }
        75% { transform: scale(0.95, 1.05); }
      `;
    }, []
  );

  const borderStyle = useMemo(
    () => {
      const borders = [
        {
          borderWidth: '3px 4px 3px 5px',
          borderRadius: '95% 4% 92% 5%/4% 95% 6% 95%',
          transform: 'rotate(2deg)',
        },
        {
          borderWidth: '3px 3px 5px 5px',
          borderRadius: '4% 95% 6% 95%/95% 4% 92% 5%',
          transform: 'rotate(-2deg)'
        },
        {
          borderWidth: '5px 3px 3px 5px',
          borderRadius: '95% 4% 97% 5%/4% 94% 3% 95%',
          transform: 'rotate(2deg)'
        }
      ];

      return shuffle(borders)[0];
    }, []
  );

  const borderColor = useMemo(
    () => {
      switch (themeName) {
        case 'blue':
          return theme.palette.secondary.light;

        default:
          return theme.palette.primary.light;
      }
    }, [theme, themeName]
  );

  return (
    <Box
      onClick={onClick}
      sx={{
        ...(
          !isEnabled && {
            filter: 'grayscale(100%)'
          }
        ),
        position: 'relative',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <Box
        component='img'
        src={`/images/bg-games/cartoon/${bgDir}/${gameType}.${bgFormat}`}
        sx={{
          position: 'relative',
          objectFit: 'contain',
          width: '100%',
          zIndex: 10,
          border: `solid ${borderColor}`,
          ...borderStyle
        }}
      />
      <Box
        component='img'
        src={getGameImagePath(gameType)}
        alt={`${gameType} logo`}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '1.2rem',
          left: '1.2rem',
          width: { xs: '4rem', md: '6rem' },
          height: { xs: '4rem', md: '6rem' },
          zIndex: 20,
          ...(
            isEnabled && !!gameBalance && {
              animation: `${balanceAnimation} 0.5s infinite linear`
            }
          ),
        }}
      />
    </Box>
  );
}
