import React, { useContext, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
//import Avatar from '@mui/material/Avatar';
import Loader from 'features/loader/Loader';
import { useAuth } from 'hooks/useAuth';
import { get, find, orderBy, includes } from 'lodash';
import CommonContext from 'features/context/commonContext';
import { useNavigate } from 'react-router-dom';
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import GameButton from './GameButton';
import { useTheme } from '@mui/material/styles';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import {
  SlotIcon,
  RouletteIcon,
  FishIcon,
} from 'features/customIcons/CustomIcons';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import useQuery from 'hooks/useQuery';

export default function Games() {
  const { games, gamesReady, gameIds, gameIdsReady } = useContext(CommonContext);
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const gameButtonVariant = get(theme, 'custom.gameButtonVariant', 'rotary');
  const { setGlobalMessage } = useGlobalMessageActionsContext();
  const query = useQuery();

  const filter = useMemo(
    () => {
      const filter = query.get('filter') || 'all';
      return filter;
    }, [query]
  );

  const filterGames = useMemo(
    () => {
      if (filter === 'all') {
        return games;
      } else {
        return games.filter((game) => game.categories.includes(filter));
      }
    }, [filter, games]
  );

  const gameItemSize = useMemo(
    () => {
      const largeButton = ['cartoon', 'mandala'];
      return includes(largeButton, gameButtonVariant) ? 6 : 4
    }, [gameButtonVariant]
  );

  const handleFilterPressed = useCallback(
    (type) => (event) => {
      event?.preventDefault();
      navigate(`/games?filter=${type}`);
    }, [navigate]
  );

  const handleDisabledPressed = useCallback(
    (event) => {
      setGlobalMessage({
          message: t(`Game unavailable`),
          severity: 'warning'
        });
    }, [setGlobalMessage, t]
  );

  const handleEnterPressed = useCallback(
    (type) => (event) => {
      event?.preventDefault();

      if (filter !== 'all') {
        navigate(`/games/${type}?filter=${filter}`);
      } else {
        navigate(`/games/${type}`);
      }
    }, [navigate, filter]
  );

  if (!gamesReady || !gameIdsReady) return (<Loader open={true} />);

  function getGameBalance(type) {
    const findGameId = find(gameIds, { gameType: type });

    if (!findGameId) {
      return 0;
    } else {
      const balanceStr = get(findGameId, 'balance.$numberDecimal', '0');
      const balance = new Decimal(balanceStr).toNumber();
      return balance;
    }
  }

  return (
    <Box
      sx={{
        maxWidth: {
          'sm': 'sm',
          'md': 'xl',
        },
        mx: 'auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2
        }}
      >
        <ButtonGroup
          variant="contained"
          color="primary"
          size='small'
        >
          <Button
            onClick={handleFilterPressed('all')}
            sx={{
              fontWeight: filter === 'all' ? 'bold' : 'normal'
            }}
          >
            {t('All')}
          </Button>
          <Button
            startIcon={<SlotIcon />}
            onClick={handleFilterPressed('slot')}
            sx={{
              fontWeight: filter === 'slot' ? 'bold' : 'normal'
            }}
          >
            {t('Slot')}
          </Button>
          <Button
            startIcon={<RouletteIcon />}
            onClick={handleFilterPressed('live')}
            sx={{
              fontWeight: filter === 'live' ? 'bold' : 'normal'
            }}
          >
            {t('Live')}
          </Button>
          <Button
            startIcon={<FishIcon />}
            onClick={handleFilterPressed('fishing')}
            sx={{
              fontWeight: filter === 'fishing' ? 'bold' : 'normal'
            }}
          >
            {t('Fishing')}
          </Button>
        </ButtonGroup>
      </Box>
      <Grid container spacing={1}>
        {
          !!filterGames.length ? (
            <>
            {
              orderBy(filterGames, ['isEnabled', 'weight', `name.${lang}`], ['desc', 'asc', 'asc']).map((game, index) => {
                const { _id: key, type, isEnabled, name } = game;
                const localedName = get(name, lang);
                const balance = getGameBalance(type);

                return (
                  <Grid
                    item
                    xs={gameItemSize}
                    sm={3}
                    md={2}
                    key={key}
                  >
                    <GameButton
                      isEnabled={isEnabled}
                      variant={gameButtonVariant}
                      gameType={type}
                      gameBalance={balance}
                      gameIndex={index}
                      name={localedName}
                      onClick={isEnabled ? handleEnterPressed(type) : handleDisabledPressed}
                    />
                  </Grid>
                );
              })
            }
            </>
          ) : (
            <Grid item xs={12}>
              <Alert severity="info">{t('No record')}</Alert>
            </Grid>
          )
        }
      </Grid>
    </Box>
  );
}
