import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { generateCsUrl, generateSmsUrl, generateCallUrl } from 'utils/social';
import ImageAvatar from 'features/avatar/ImageAvatar';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/PhoneTwoTone';
import ChatIcon from '@mui/icons-material/ChatTwoTone';
import { LineIcon } from 'features/customIcons/CustomIcons';

export default function ContactDialog(props) {
  const { open, onClose, contact = null } = props;
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { t } = useTranslation();

  const data = useMemo(
    () => {
      if (!contact) return null;
      const name = get(contact, `name.${lang}`, '');
      const status = get(contact, `status.${lang}`, '');
      const avatar = get(contact, 'avatar');
      const waUrl = generateCsUrl(contact, 'whatsapp');
      const tgUrl = generateCsUrl(contact, 'telegram');
      const lineUrl = generateCsUrl(contact, 'line');
      const smsUrl = generateSmsUrl(contact);
      const callUrl = generateCallUrl(contact);
      const noContact = (!waUrl && !tgUrl && !smsUrl && !callUrl) ? true : false;

      return {
        name,
        status,
        avatar,
        waUrl,
        tgUrl,
        lineUrl,
        smsUrl,
        callUrl,
        noContact,
      }
    }, [contact, lang]
  );

  const handleLinkClick = (url) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const handleClose = () => {
    onClose();
  };

  if (!data) return null;

  return (
    <Dialog fullWidth maxWidth='xs' onClose={handleClose} open={open}>
{/*      <DialogTitle sx={{ textAlign: 'center' }}>
        {
          t('24/7 Support')
        }
      </DialogTitle>*/}
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box textAlign='center' display='flex' justifyContent='center' alignItems='center'>
              {
                data.avatar ? (
                  <ImageAvatar
                    sx={{ width: '7rem', height: '7rem' }}
                    alt={`${data.name}-${data.status}`}
                    imageId={data.avatar}
                  />
                  ) : (
                  <Avatar
                    sx={{
                      bgcolor: red[100],
                      color: red[600],
                      width: '7rem',
                      height: '7rem'
                    }}
                  >
                    <PersonIcon sx={{
                        width: '4rem',
                        height: '4rem'
                      }}
                    />
                  </Avatar>
                )
              }
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
            <Typography variant='h6' gutterBottom>
              {
                data.name
              }
            </Typography>
            <Typography variant='subtitle2' sx={{ fontStyle: 'oblique', color: 'text.secondary', fontWeight: 300 }} gutterBottom>
              {
                data.status
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
          <Grid item xs={3}>
            <Button
              disabled={!data.waUrl}
              color='secondary'
              size='large'
              fullWidth
              variant='contained'
              onClick={() => handleLinkClick(data.waUrl)}
              sx={{
                height: '3rem',
                borderBottom: '5px solid #25D366',
              }}
            >
              <WhatsAppIcon />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={!data.tgUrl}
              color='secondary'
              size='large'
              fullWidth
              variant='contained'
              onClick={() => handleLinkClick(data.tgUrl)}
              sx={{
                height: '3rem',
                borderBottom: '5px solid #229ED9',
              }}
            >
              <TelegramIcon />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={!data.lineUrl}
              color='secondary'
              size='large'
              fullWidth
              variant='contained'
              onClick={() => handleLinkClick(data.lineUrl)}
              sx={{
                height: '3rem',
                borderBottom: '5px solid #00B900',
              }}
            >
              <LineIcon />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={!data.smsUrl}
              sx={{
                height: '3rem',
              }}
              color='secondary'
              size='large'
              fullWidth
              variant='contained'
              onClick={() => handleLinkClick(data.smsUrl)}
            >
              <ChatIcon />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={!data.callUrl}
              sx={{
                height: '3rem',
              }}
              color='secondary'
              size='large'
              fullWidth
              variant='contained'
              onClick={() => handleLinkClick(data.callUrl)}
            >
              <CallIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} autoFocus>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
