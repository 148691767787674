import ContentContext from './guestContext';
import feathers from 'services/feathers';
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import useQuery from 'hooks/useQuery';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

const COMPANY_ID = (process.env.REACT_APP_COMPANY_ID || '');

function GuestProvider({ children }) {
  const [ companyId, setCompanyId ] = useState(COMPANY_ID);
  const [ supportedLocales, setSupportedLocales ] = useState(['en']);
  const [ defaultLocale, setDefaultLocale ] = useState('en');
  const [ supportedCountryCodes, setSupportedCountryCodes ] = useState([]);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ ready, setReady ] = useState(false);

  const query = useQuery();

  useEffect(
    () => {
      const companyIdFromStorage = localStorage.getItem('org');
      if (!companyIdFromStorage) return;
      setCompanyId(companyIdFromStorage);
    }, []
  );

  useEffect(
    () => {
      const companyIdFromQuery = query.get('org');
      if (!companyIdFromQuery) return;
      setCompanyId(companyIdFromQuery);
    }, [query]
  );

  useEffect(
    () => {
      if (!companyId) return;

      async function getSupportedCountryCodes() {
        try {
          const { data } = await feathers.service('company-settings').find({
            query: {
              companyId
            }
          });

          const companySetting = get(data, '[0]', {});
          const supportedLocales = get(companySetting, 'supportedLocales', ['en']);
          const supportedCountryCodes = get(companySetting, 'supportedCountryCodes', []);
          const defaultLocale = get(companySetting, 'defaultLocale', 'en');

          const defaultLocaleIndex = supportedLocales.indexOf(defaultLocale);
          if (defaultLocaleIndex > 0) {
            supportedLocales.splice(defaultLocaleIndex, 1);
            supportedLocales.unshift(defaultLocale);
          }

          setSupportedLocales(supportedLocales);
          setDefaultLocale(defaultLocale);
          setSupportedCountryCodes(supportedCountryCodes);
        } catch (err) {
          setGlobalErrorMessage({ err });
        } finally {
          setReady(true);
        }
      }

      getSupportedCountryCodes();
    }, [companyId, setGlobalErrorMessage]
  );

  return (
    <ContentContext.Provider value={{
      companyId,
      supportedLocales,
      defaultLocale,
      supportedCountryCodes,
      ready,
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default GuestProvider;
