import React from 'react';
import { get } from 'lodash';
import AvatarLogo from './header/AvatarLogo';
import MediaLogo from './header/MediaLogo';

export default function CardHeader(props) {
  const variant = get(props, 'variant', 'avatar');

  if (variant === 'avatar') return <AvatarLogo />
  if (variant === 'media') return <MediaLogo />

  return null;
}
