import ContentContext from './supportContext';
import React, { useState, useEffect, useMemo } from 'react';
import useFeathers from 'hooks/useFeathers';
import useQuery from 'hooks/useQuery';

const COMPANY_ID = (process.env.REACT_APP_COMPANY_ID || '');

function SupportProvider({ children }) {
  const query = useQuery();
  const [ companyId, setCompanyId ] = useState(COMPANY_ID);

  useEffect(
    () => {
      const companyIdFromStorage = localStorage.getItem('org');
      if (!companyIdFromStorage) return;
      setCompanyId(companyIdFromStorage);
    }, []
  );

  useEffect(
    () => {
      const companyIdFromQuery = query.get('org');
      if (!companyIdFromQuery) return;
      setCompanyId(companyIdFromQuery);
    }, [query]
  );

  const queryFetchAll = useMemo(
    () => {
      return {
        query: {
          companyId,
          $limit: -1
        }
      };
    }, [companyId]
  );

  const { data: waBots, ready: waBotsReady } = useFeathers('whatsapp-bots', queryFetchAll);
  const { data: tgBots, ready: tgBotsReady } = useFeathers('telegram-bots', queryFetchAll);
  const { data: lineBots, ready: lineBotsReady } = useFeathers('line-bots', queryFetchAll);
  const { data: customerServices, ready: customerServicesReady } = useFeathers('customer-services', queryFetchAll);

  return (
    <ContentContext.Provider value={{
      waBots, // no live event
      waBotsReady,
      tgBots, // no live event
      tgBotsReady,
      lineBots, // no live event
      lineBotsReady,
      customerServices,
      customerServicesReady,
      ready: (waBotsReady && tgBotsReady && customerServicesReady) ? true : false
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default SupportProvider;
