import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';

export default function CustomCopyToClipboard({ children, text, onCopy, copiedText = 'Copied' }) {
  const { setGlobalMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();

  function handleOnCopy() {
    setGlobalMessage({
      message: t(copiedText),
      severity: 'info',
      duration: 1000
    })
  }

  return (
    <CopyToClipboard text={text} onCopy={handleOnCopy}>
      {children}
    </CopyToClipboard>
  );
}
