import React, { useState, useMemo, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import useFeathers from 'hooks/useFeathers';
import Box from '@mui/material/Box';
import { isInWebApp } from 'utils/pwa';
import useQuery from 'hooks/useQuery';
import useGameLogo from 'hooks/useGameLogo';

const COMPANY_ID = (process.env.REACT_APP_COMPANY_ID || '');

export default function Showcase(props) {
  const [ playMarquee, setPlayMarquee ] = useState(true);
  const query = useQuery();
  const [ companyId, setCompanyId ] = useState(COMPANY_ID);
  const { getGameImagePath } = useGameLogo();

  useEffect(
    () => {
      const companyIdFromStorage = localStorage.getItem('org');
      if (!companyIdFromStorage) return;
      setCompanyId(companyIdFromStorage);
    }, []
  );

  useEffect(
    () => {
      const companyIdFromQuery = query.get('org');
      if (!companyIdFromQuery) return;
      setCompanyId(companyIdFromQuery);
    }, [query]
  );

  const queryFetchAll = useMemo(
    () => {
      return {
        query: {
          companyId,
          $limit: -1
        }
      };
    }, [companyId]
  );

  const { data } = useFeathers('games', queryFetchAll);

  const games = useMemo(
    () => {
      return data.filter(g => !!g.isEnabled);
    }, [data]
  );

  const handleMarqueeClicked = (event) => {
    event?.preventDefault();
    setPlayMarquee(prev => (!prev));
  };

  if (!games.length || isInWebApp) return null;

  return (
    <Box sx={{ p: 0.5 }} onClick={handleMarqueeClicked}>
      <Marquee gradient={false}
        {
          ...{
            play: playMarquee,
            speed: 20,
            delay: 0,
            loop: 0
          }
        }
      >
        {
          games.map(function (game) {
            const { _id: key, type } = game;
            return (
              <Box sx={{ width: '3rem', mx: 1 }} key={key} component='img' src={getGameImagePath(type)} alt={`${type} logo`} />
            )
          })
        }
      </Marquee>
    </Box>
  );
}
