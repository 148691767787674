import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useAuth } from 'hooks/useAuth';
import { get, find, includes } from 'lodash';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import CommonContext from 'features/context/commonContext';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import Decimal from 'decimal.js';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { keyframes } from '@mui/system';
import CountUp from 'react-countup';
import AddIcon from '@mui/icons-material/AddTwoTone';
import MinusIcon from '@mui/icons-material/RemoveTwoTone';
import { LoadingButton } from '@mui/lab';
import useGameLogo from 'hooks/useGameLogo';
import BalanceTransferDialog from 'features/balanceTransferDialog/BalanceTransferDialog.js';
import ApiMenu from '../../gameMenu/variant/ApiMenu';

const MIN_AMOUNT = 0.01;
const MAX_AMOUNT = 30000;

export default function Game() {
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { gameType } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wallet, walletReady, games, gamesReady, gameIds, gameIdsReady } = useContext(CommonContext);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ cuBalance, setCuBalance ] = useState({ start: 0, end: 0 });
  const [ notFound, setNotFound ] = useState(false);
  const [ status, setStatus ] = useState('idle');
  const { getGameImagePath } = useGameLogo();
  const [ openBalanceTransferDialog, setOpenBalanceTransferDialog ] = useState({
    open: false,
    mode: '',
    minAmount: 0,
    maxAmount: 0,
    presetAmountMultiplier: [0.5, 1]
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isIdle = useMemo(
    () => {
      return (status === 'idle') ? true : false;
    }, [status]
  );

  const showLinearProgress = useMemo(
    () => {
      const supported = ['create', 'patch-password', 'patch-release', 'startGame'];
      return includes(supported, status);
    }, [status]
  );

  const showBalanceProgress = useMemo(
    () => {
      const supported = ['transfer', 'patch-balance'];
      return includes(supported, status);
    }, [status]
  );

  const gameId = useMemo(
    () => {
      if (!gameIdsReady) return null;
      const findGameId = find(gameIds, { gameType: gameType });

      if (!findGameId) return null;
      return findGameId;
    }, [gameIds, gameIdsReady, gameType]
  );

  const gameBalance = useMemo(
    () => {
      if (!gameId) return 0;
      const gameBalance = new Decimal(get(gameId, 'balance.$numberDecimal', '0'));
      return gameBalance.toNumber();
    }, [gameId]
  );

  useEffect(() => {
    setCuBalance(prev => {
      if (prev?.end === gameBalance) {
        return prev;
      }
      return {
        start: prev?.end, end: gameBalance
      }
    });
  }, [gameBalance]);

  const decCashBalance = useMemo(
    () => {
      if (!walletReady) new Decimal(0);
      const cashBalance = new Decimal(get(wallet, 'cashBalance.$numberDecimal', '0'));
      return cashBalance;
    }, [walletReady, wallet]
  );

  const decMinDpAmount = useMemo(
    () => {
      const min = new Decimal(MIN_AMOUNT);
      return decCashBalance.lt(min) ? decCashBalance : min;
    }, [decCashBalance]
  );

  const decMaxDpAmount = useMemo(
    () => {
      const max = new Decimal(MAX_AMOUNT);
      return decCashBalance.gt(max) ? max : decCashBalance;
    }, [decCashBalance]
  );

  const decMinWdAmount = useMemo(
    () => {
      const min = new Decimal(MIN_AMOUNT);
      const decGameBalance = new Decimal(gameBalance);
      return decGameBalance.lt(min) ? decGameBalance : min;
    }, [gameBalance]
  );

  const decMaxWdAmount = useMemo(
    () => {
      const max = new Decimal(MAX_AMOUNT);
      const decGameBalance = new Decimal(gameBalance);
      return decGameBalance.gt(max) ? max : decGameBalance;
    }, [gameBalance]
  );

  const game = useMemo(
    () => {
      if (!gamesReady) return null;
      const findGame = find(games, { type: gameType, isEnabled: true });
      if (!findGame) {
        setNotFound(true);
        return null;
      } else {
        return findGame;
      }
    }, [games, gamesReady, gameType]
  );

  const gameName = useMemo(
    () => {
      return game?.name[lang] || 'Unknown';
    }, [game, lang]
  );

  const recentPayouts = useMemo(
    () => {
      const { recentPayouts = [] } = game || {};
      return recentPayouts;
    }, [game]
  );

  useEffect(() => {
    if (!notFound) return;
    navigate('/games', { replace: true });
  }, [notFound, navigate]);

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('balance-transfers');

    const onPatched = (data) => {
      const state = get(data, 'state');
      const type = get(data, 'type');

      if (isMounted) {
        setStatus('idle');
        if (state === 'done') {
          setGlobalMessage({
            message: t(`point ${type} approved`),
            severity: 'success'
          });
        } else if (state === 'canceled') {
          setGlobalMessage({
            message: t(`point ${type} rejected`),
            severity: 'error'
          });
        } else if (state === 'manual') {
          setGlobalMessage({
            message: t(`point ${type} manual`),
            severity: 'info'
          });
        }
      }
    };

    const onRemoved = (data) => {
      const type = get(data, 'type');
      if (isMounted) {
        setStatus('idle');
        setGlobalMessage({
          message: t(`point ${type} rejected`),
          severity: 'error'
        });
      }
    };

    service.on('patched', onPatched);
    service.on('removed', onRemoved);

    return () => {
      isMounted = false;
      service.removeListener('patched', onPatched);
      service.removeListener('removed', onPatched);
    };

  }, [setGlobalMessage, t]);

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('game-ids');

    const setStatusToIdle = () => {
      if (isMounted) setStatus('idle');
    };

    const onFailed = (data) => {
      if (isMounted) {
        const { error } = data;
        setStatus('idle');
        setGlobalErrorMessage({ err: error });
      }
    };

    service.on('created', setStatusToIdle);
    service.on('patched', setStatusToIdle);
    service.on('removed', setStatusToIdle);
    service.on('failed', onFailed);

    return () => {
      isMounted = false;
      service.removeListener('created', setStatusToIdle);
      service.removeListener('patched', setStatusToIdle);
      service.removeListener('removed', setStatusToIdle);
      service.removeListener('failed', onFailed);

    };
  }, [setGlobalErrorMessage, t]);

  const noIdAnimation = useMemo(
    () => {
      return keyframes`
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.6;
        },
        100% {
          opacity: 1;
        }
      `;
    }, []
  );

  const handleCreateGameId = async (event) => {
    event.preventDefault();

    if (!isIdle || !!gameId) return;

    try {
      setStatus('create');
      await feathers.service('game-ids').create({
        gameType,
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  };

  const handleBalanceCheck = async (event) => {
    event.preventDefault();

    if (!isIdle || !gameId) return;

    try {
      setStatus('patch-balance');
      const { _id: gameUid } = gameId;
      await feathers.service('game-ids').patch(gameUid, {
        lastBalanceCheckRequestAt: new Date(),
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
      setStatus('idle');
    }
  };

  function onDepositClick(event) {
    event.preventDefault();
    setOpenBalanceTransferDialog({
      open: true,
      mode: 'deposit',
      minAmount: decMinDpAmount.toNumber(),
      maxAmount: decMaxDpAmount.toNumber(),
    });
  }

  function onWithdrawalClick(event) {
    event.preventDefault();
    setOpenBalanceTransferDialog({
      open: true,
      mode: 'withdrawal',
      minAmount: decMinWdAmount.toNumber(),
      maxAmount: decMaxWdAmount.toNumber(),
    });
  }

  function handleBalanceTransferDialogClose() {
    setOpenBalanceTransferDialog({
      open: false,
      mode: '',
      minAmount: 0,
      maxAmount: 0,
    });
  }

  function handleBalanceTransferDialogSubmit({ amount, mode }) {
    createBalanceTransferAsync(amount, mode);
    setOpenBalanceTransferDialog({ ...openBalanceTransferDialog, open: false, mode: ''});
  }

  async function createBalanceTransferAsync (amount, mode) {
    try {
      setStatus('transfer');
      await feathers.service(`/balance-transfers`).create({
        amount: amount,
        type: mode,
        gameType
      });
    } catch (err) {
      setStatus('idle');
      setGlobalErrorMessage({ err });
    }
  }

  return (
    <>
      <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
        <BalanceTransferDialog
          open={openBalanceTransferDialog.open}
          mode={openBalanceTransferDialog.mode}
          minAmount={openBalanceTransferDialog.minAmount}
          maxAmount={openBalanceTransferDialog.maxAmount}
          presetAmountMultiplier={openBalanceTransferDialog.presetAmountMultiplier}
          onClose={handleBalanceTransferDialogClose}
          onSubmit={handleBalanceTransferDialogSubmit}
        />

        {
          (!gamesReady || !gameIdsReady || !game) && <LinearProgress />
        }
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='title' gutterBottom color='text.secondary' sx={{ fontStyle: 'italic' }}>
                {gameName}
              </Typography>
              <Typography variant='title' gutterBottom color='text.secondary' sx={{ fontStyle: 'italic', fontWeight: 700 }}>
                {t('Game Balance')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Avatar
                src={getGameImagePath(gameType)}
                variant="rounded"
                sx={{
                  width: 48,
                  height: 48,
                }}
              />
              <CountUp
                  start={cuBalance.start}
                  end={cuBalance.end}
                  duration={0.88}
                  separator=" "
                  decimals={2}
                  decimal="."
                  prefix=''
                  suffix=''
                >
                  {({ countUpRef }) => (
                    <Typography variant="h5" ref={countUpRef} />
                  )}
              </CountUp>
            </Box>
            {
              !!showLinearProgress && <LinearProgress />
            }
            <Box sx={{ mt: 2 }}>
              <Stack direction='row' spacing={1}>
                <Button disabled={!gameId} size='medium' color='primary' fullWidth variant='contained' onClick={onDepositClick}>
                  <AddIcon />
                </Button>
                <LoadingButton color='secondary' disabled={!gameId} loading={showBalanceProgress} size='medium' variant='contained' fullWidth onClick={handleBalanceCheck}>
                  <RefreshIcon />
                </LoadingButton>
                <Button disabled={!gameId} size='medium' color='primary' fullWidth variant='contained' onClick={onWithdrawalClick}>
                  <MinusIcon />
                </Button>
              </Stack>
            </Box>
          </CardContent>
          <Divider />
          {
            !gameId &&
            <CardActions>
              <Button size='large' sx={{
                fontWeight: 700,
                animation: `${noIdAnimation} 1s ease 10`,
                animationDelay: '1.0s'
              }} fullWidth variant='contained' onClick={handleCreateGameId}>
                {t('Get game ID')}
              </Button>
            </CardActions>
          }
        </Card>
      </Box>
      {
        gameId &&
        <Box>
          <ApiMenu gameType={gameType} recentPayouts={recentPayouts} />
        </Box>
      }
    </>
  );
}
