import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { get, includes } from 'lodash';
import { hex2rgba, hex2HueRotationAngle } from 'utils/color';
import useGameLogo from 'hooks/useGameLogo';

import goldenBase from 'assets/images/game/golden/base.png';
import goldenGlowOuter from 'assets/images/game/golden/glow_outer.png';
import goldenGlowInner from 'assets/images/game/golden/glow_inner.png';
import goldenParticles from 'assets/images/game/golden/particles.png';

export default function RotaryButton(props) {
  const { isEnabled, gameType, gameBalance, onClick } = props;
  const theme = useTheme();
  const themeName = get(theme, 'custom.name');
  const { getGameImagePath } = useGameLogo();

  const isNativeTheme = useMemo(
    () => {
      const nativeThemes = ['golden', 'australia'];
      return includes(nativeThemes, themeName);
    }, [themeName]
  );

  const gameBalanceAnimation = useMemo(
    () => {
      return keyframes`
        from, to { transform: translate(-50%, -50%) transform: scale(1, 1); }
        25% { transform: translate(-50%, -50%) scale(0.9, 1.1); }
        50% { transform: translate(-50%, -50%) scale(1.1, 0.9); }
        75% { transform: translate(-50%, -50%) scale(0.95, 1.05); }
      `;
    }, []
  );

  const borderColor = useMemo(
    () => {
      if (isNativeTheme) return theme.palette.secondary.main;

      switch (themeName) {
        case 'golden':
          return theme.palette.secondary.main;

        case 'blue':
          return theme.palette.background.paper;

        default:
          return theme.palette.primary.light;
      }
    }, [theme, themeName, isNativeTheme]
  );

  const imageFilter = useMemo(
    () => {
      if (isNativeTheme) return null;
      if (themeName === 'night') return 'grayScale(100%)';

      const hueRotateAngle = hex2HueRotationAngle(get(theme, 'palette.primary.main', '#FFFFFF'), -40);

      return `hue-rotate(${hueRotateAngle}deg)`;
    }, [theme, themeName, isNativeTheme]
  );

  const bgColor = useMemo(
    () => {
      if (isNativeTheme) return '#0b0f0f';

      return hex2rgba(get(theme, 'palette.primary.main', '#FFFFFF'), 0.2)
    }, [theme, isNativeTheme]
  );

  const lightAnimation = useMemo(
    () => {
      return keyframes`
         0% {
          display: block;
          opacity: 1;
        }
        50% {
          display: block;
          opacity: 0.3;
        }

        100% {
          display: block;
          opacity: 1;
        }
      `;
    }, []
  );

  const spinAnimation = useMemo(
    () => {
      return keyframes`
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      `;
    }, []
  );

  const spinReverseAnimation = useMemo(
    () => {
      return keyframes`
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      `;
    }, []
  );

  return (
    <Box sx={{
      ...(
        !isEnabled && {
          filter: 'grayscale(100%)'
        }
      ),
      mt: 1,
      position: 'relative',
      width: { xs: '6.8rem', md: '10rem' },
      height: { xs: '6.8rem', md: '10rem' },
      overflow: 'hidden',
      mx: 'auto',
      bgcolor: bgColor,
      borderRadius: { xs: '6.8rem', md: '10rem' },
      border: `6px ${borderColor}`,
      borderStyle: 'ridge',
    }}>
      <Box
        component='img'
        src={goldenGlowOuter}
        sx={{
          position: 'absolute',
          width: { xs: '6.8rem', md: '10rem' },
          height: { xs: '6.8rem', md: '10rem' },
          left: '50%',
          top: '50%',
          zIndex: 16,
          transform: 'translate(-50%, -50%)',
          filter: imageFilter,
          ...(
            isEnabled && {
              animation: `${lightAnimation} 0.5s infinite linear`,
            }
          )
        }}
      />
        <Box
          component='img'
          src={goldenGlowInner}
          sx={{
            position: 'absolute',
            width: { xs: '6.8rem', md: '10rem' },
            height: { xs: '6.8rem', md: '10rem' },
            left: '50%',
            top: '50%',
            zIndex: 18,
            transform: 'translate(-50%, -50%)',
            filter: imageFilter,
            ...(
              isEnabled && {
                animation: `${lightAnimation} 1s infinite linear`,
              }
            )
          }}
        />
        <Box
          component='img'
          src={goldenBase}
          sx={{
            position: 'absolute',
            width: { xs: '6.8rem', md: '10rem' },
            height: { xs: '6.8rem', md: '10rem' },
            left: '50%',
            top: '50%',
            zIndex: 20,
            transform: 'translate(-50%, -50%)',
            filter: imageFilter,
            ...(
              isEnabled && !!gameBalance && {
                animation: `${spinReverseAnimation} 0.3s infinite linear`,
              }
            )
          }}
        />
        <Box
          component='img'
          src={goldenParticles}
          sx={{
            position: 'absolute',
            width: { xs: '6.8rem', md: '10rem' },
            height: { xs: '6.8rem', md: '10rem' },
            left: '50%',
            top: '50%',
            zIndex: 19,
            transform: 'translate(-50%, -50%)',
            filter: imageFilter,
            ...(
              isEnabled && {
                animation: `${spinAnimation} ${!!gameBalance ? '3s' : '12s'} infinite linear`,
              }
            )
          }}
        />
      <Box
        onClick={onClick}
        component='img'
        src={getGameImagePath(gameType, {
          forceDarkPalette: true,
        })}
        alt={`${gameType} logo`}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '4.5rem', md: '6rem' },
          height: { xs: '4.5rem', md: '6rem' },
          zIndex: 30,
          ...(
            isEnabled && !!gameBalance && {
              animation: `${gameBalanceAnimation} 0.5s infinite linear`
            }
          ),
        }}
      />
    </Box>
  );
}
