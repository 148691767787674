import React, { useMemo, useCallback, useContext } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import CommonContext from 'features/context/commonContext';
import { get } from 'lodash';
import { useUserConfig } from 'hooks/useUserConfig';
import { useLocation } from "react-router-dom";
import BalanceBarCompact from './BalanceBarCompact';
import { useTranslation } from 'react-i18next';

import DarkModeIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeIcon from '@mui/icons-material/LightModeTwoTone';
import ShareIcon from '@mui/icons-material/ShareTwoTone';
import CallIcon from '@mui/icons-material/SupportAgentTwoTone';
import ChatIcon from '@mui/icons-material/ChatBubbleTwoTone';

export default function ExtraNavigation(props) {
  const { onItemClicked } = props;
  const location = useLocation();
  const { darkMode, toggleDarkMode, compactAppBar } = useUserConfig();
  const { t } = useTranslation();
  const { companySetting } = useContext(CommonContext);

  const pathName = useMemo(
    () => {
      return get(location, 'pathname');
    }, [location]
  );

  const directChatLink = useMemo(
    () => {
      return companySetting?.socialChannels?.directChatLink ?? '';
    }, [companySetting]
  );

  const handleDarkModeToggle = useCallback(
    (event) => {
      event?.preventDefault();
      toggleDarkMode();
    }, [toggleDarkMode]
  );

  const onDirectChatLinkClicked = useCallback(
    (event) => {
      event?.preventDefault();
      if (!directChatLink) return;
      window.open(directChatLink, '_blank');
    }, [directChatLink]
  );

  return (
    <>
      <Stack direction='row' spacing={1}>
        {
          !!compactAppBar ?
          <>
            <Tooltip title={t('Share')}>
              <IconButton color={pathName === '/referral' ? 'secondary' : 'inherit'} variant='contained' size='small' onClick={onItemClicked('referral')}>
                <ShareIcon />
              </IconButton>
            </Tooltip>

            {
              !!directChatLink ?
              <Tooltip title={t('Chat')}>
                <IconButton color='inherit' variant='contained' size='small' onClick={onDirectChatLinkClicked}>
                  <ChatIcon />
                </IconButton>
              </Tooltip> :
              <Tooltip title={t('Contact')}>
                <IconButton color={pathName === '/contact' ? 'secondary' : 'inherit'} variant='contained' size='small' onClick={onItemClicked('contact')}>
                  <CallIcon />
                </IconButton>
              </Tooltip>
            }
          </> :
          <>
            <Button startIcon={<ShareIcon />} variant='contained' size='small' onClick={onItemClicked('referral')}>
              {t('Share')}
            </Button>
            {
              !!directChatLink ?
              <Button startIcon={<ChatIcon />} variant='contained' size='small' onClick={onDirectChatLinkClicked}>
                {t('Chat')}
              </Button> :
              <Button startIcon={<CallIcon />} variant='contained' size='small' onClick={onItemClicked('contact')}>
                {t('Contact')}
              </Button>
            }
          </>
        }
        <Tooltip title={t(darkMode ? 'Switch to light mode' : 'Switch to dark mode')}>
          <IconButton color='inherit' variant='contained' size='small' onClick={handleDarkModeToggle}>
          {
            darkMode ? <LightModeIcon /> : <DarkModeIcon />
          }
          </IconButton>
        </Tooltip>
      </Stack>
      {
        !!compactAppBar && <BalanceBarCompact />
      }
    </>
  );
}
