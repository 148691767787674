const lookups = {
  "other": "Other",
  "my_duitnow_bank": "Bank Account",
  "my_duitnow_mobile": "Mobile Number",
  "my_duitnow_nric": "NRIC (MyKad)",
  "my_duitnow_passport": "Passport Number",
  "my_duitnow_army_police": "My Tentera ID / Police Number",
  "my_duitnow_business_registration": "Business Registration Number",
  "my_affin": "Affin",
  "my_agrobank": "Agrobank",
  "my_alliance": "Alliance",
  "my_ambank": "AmBank",
  "my_bsn": "BSN",
  "my_cimb": "CIMB",
  "my_hongleong": "Hong Leong",
  "my_hsbc": "HSBC",
  "my_islam": "Islam",
  "my_maybank": "Maybank",
  "my_muamalat": "Muamalat",
  "my_ocbc": "OCBC",
  "my_public": "Public",
  "my_rakyat": "Rakyat",
  "my_rhb": "RHB",
  "my_standardchartered": "Standard Chartered",
  "my_uob": "UOB",
  "sg_paynow_mobile": "Mobile Number",
  "sg_paynow_nric_fin": "NRIC / FIN",
  "sg_paynow_uen": "UEN",
  "sg_paynow_vpa": "VPA",
  "sg_anz": "ANZ",
  "sg_bangkokbank": "Bangkok Bank",
  "sg_bankofchina": "Bank Of China",
  "sg_bankofsg": "Bank of Singapore",
  "sg_bnpparibas": "BNP Paribas",
  "sg_cimb": "CIMB Bank",
  "sg_citi": "Citibank",
  "sg_hongleong": "Hong Leong Finance",
  "sg_hsbc": "HSBC",
  "sg_icici": "ICICI Bank",
  "sg_indcommbankofchina": "Industrial Commercial Bank Of China",
  "sg_indiabank": "Bank of India",
  "sg_indianoverseas": "Indian Overseas Bank",
  "sg_islamic": "Islamic Bank of Asia",
  "sg_jpmorgan": "J.P. Morgan",
  "sg_mari": "MariBank",
  "sg_maybank": "Maybank",
  "sg_ocbc": "OCBC",
  "sg_posb": "POSB DBS",
  "sg_rhb": "RHB Bank",
  "sg_sbi": "SBI",
  "sg_standardchartered": "Standard Chartered",
  "sg_trustbank": "Trust Bank Limited",
  "sg_uob": "UOB",
  "au_payid_mobile": "Mobile Number",
  "au_payid_email": "Email",
  "au_payid_abn": "ABN",
  "au_payid_org": "Organisation ID",
  "au_anz_bsb": "Australia & New Zealand Banking Corp",
  "au_anzp_bsb": "ANZ Plus",
  "au_ben_bsb": "Bendigo & Adelaide Bank",
  "au_bfl_bsb": "BSP Financial Group",
  "au_boq_bsb": "Bank of Queensland",
  "au_cba_bsb": "Commonwealth Bank",
  "au_lfg_bsb": "Liberty Financial Group",
  "au_mqg_bsb": "Macquarie Bank",
  "au_nab_bsb": "National Australia Bank",
  "au_npb_bsb": "Newcastle Permanent Bank",
  "au_stg_bsb": "St.George Bank",
  "au_upb_bsb": "Up Bank",
  "au_vuk_bsb": "Virgin Money",
  "au_wbc_bsb": "Westpac",
  "nz_payidnz_mobile": "Mobile Number",
  "nz_payidnz_email": "Email",
  "nz_anz": "ANZ Bank New Zealand",
  "nz_asb": "ASB Bank",
  "nz_bank_of_china": "Bank of China (New Zealand) Limited",
  "nz_bank_of_new_zealand": "Bank of New Zealand",
  "nz_heartland_bank": "Heartland Bank Limited",
  "nz_hsbc": "HSBC New Zealand",
  "nz_icbc": "Industrial and Commercial Bank of China (New Zealand) Limited",
  "nz_kiwibank": "Kiwibank Limited",
  "nz_rabobank": "Rabobank New Zealand Limited",
  "nz_sbs_bank": "SBS Bank",
  "nz_the_cooperative_bank": "The Co-operative Bank Limited",
  "nz_tsb_bank": "TSB Bank Limited",
  "nz_westpac": "Westpac New Zealand Limited",
  "nz_hatch": "Hatch",
  "nz_hnry": "Hnry",
  "nz_juno": "Juno",
  "nz_loaded_for_travel": "Loaded for Travel",
  "nz_sharesies": "Sharesies",
  "nz_wise": "Wise",
  "th_kasikornbank": "Kasikornbank",
  "th_siamcommercialbank": "Siam Commercial Bank",
  "th_bangkokbank": "Bangkok Bank",
  "th_tmbthanachartbank": "TMBThanachart Bank (TTB)",
  "th_krungthaibank": "Krung Thai Bank",
  "th_bankofthailand": "Bank of Thailand",
  "th_cathayunitedbank": "Cathay United Bank",
  "th_cimb": "CIMB",
  "th_citi": "Citibank",
  "th_gsb": "Government Savings Bank",
  "th_icbc": "ICBC",
  "th_kiatnakinphatrabank": "Kiatnakin Phatra Bank",
  "th_krungsri": "Krungsri",
  "th_mizuhobangkokbranch": "Mizuho Bank Bangkok Branch",
  "th_mufgbank": "MUFG Bank",
  "th_ocbc": "OCBC",
  "th_standardchartered": "Standard Chartered",
  "th_thanachartbank": "Thanachart Bank",
  "th_tiscobank": "Tisco Bank",
  "th_unitedoverseasbank": "United Overseas Bank"
};

const enums = Object.keys(lookups);

function getLookupsByCountry(country) {
  const ret = enums.reduce((acc, key) => {
    if (key.startsWith(country)) {
      return {
        ...acc,
        [key]: lookups[key]
      };
    }

    return acc;
  }, {});

  const other = lookups["other"];

  return {
    ...ret,
    other
  };
}

export {
  lookups,
  enums,
  getLookupsByCountry,
};
