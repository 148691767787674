import React, { useMemo } from 'react';
import APIGame from './game/APIGame';
import KioskGame from './game/KioskGame';
import {
  useParams
} from "react-router-dom";

export default function Game() {
  const { gameType } = useParams();

  const gameProcessingType = useMemo(
    () => {
      return gameType.endsWith('-api') ? 'api' : 'kiosk';
    }, [gameType]
  );

  return (
    gameProcessingType === 'api' ?
      <APIGame />
      :
      <KioskGame />
  );
}
